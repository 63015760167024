import React from "react";
import axios from "axios";
import configApi from "../../services/config.json";
import Joi from "joi-browser";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Danger from "components/Typography/Danger.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import navlinks from "routes.js";
import logo from "assets/img/Fanbies_logo.png";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import image from "assets/img/sidebar-1.jpg";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import InputLabel from "@material-ui/core/InputLabel";

class AddAffiliate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image,
      color: "blue",
      mobileOpen: false,
      error: "",
      submitDisabled: false,
      affStatusOption: ["active", "disabled"],
      affStatus: "",
      successMessage: "",
      imagePreviewUrl: "",
      feedImgFile: null,
      title: "",
      program: "",
      commission: "",
      salePrice: 0,
      link: "",
      description: ""
    };
    this.fileInput = React.createRef();
  }

  addAffiliate = () => {
    const {
      affStatus,
      title,
      commission,
      salePrice,
      link,
      description,
      program,
      feedImgFile
    } = this.state;
    if (localStorage.getItem("token") === null || feedImgFile === null) return;

    let data = new FormData();
    data.append("file", feedImgFile);
    data.set("title", title);
    data.set("jtoken", localStorage.getItem("token"));
    data.set("affStatus", affStatus);
    data.set("commission", commission);
    data.set("salePrice", salePrice);
    data.set("link", link);
    data.set("description", description);
    data.set("program", program);

    this.setState({ successMessage: "" });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data,
      url: configApi.createaffiliateitem
    };

    axios(options)
      .then(res => {
        const uRes = res.data;
        this.setState({ submitDisabled: false });
        if (!uRes.success) return this.setState({ error: uRes.message });

        this.setState({ successMessage: uRes.message });
        setTimeout(() => {
          this.props.history.replace("/dashboard");
        }, 900);
      })
      .catch(() => {
        // console.log("😱", e);
        this.setState({ submitDisabled: false });
      });
  };

  handleChange = ({ currentTarget: input }) => {
    const formState = { ...this.state };
    formState[input.name] = input.value;
    this.setState(formState);
  };

  validateForm = e => {
    e.preventDefault();
    const { title, commission, link, description, salePrice } = this.state;
    const validatorObj = {
      title,
      commission,
      salePrice,
      link,
      description
    };
    const result = Joi.validate(validatorObj, this.schema);
    this.setState({ error: "" });
    if (result.error !== null)
      return this.setState({ error: result.error.details[0].message });

    this.setState({ submitDisabled: true });
    this.addAffiliate();
  };

  // schema for form validation
  schema = {
    commission: Joi.string()
      .required()
      .label("Error: What is the commission "),
    salePrice: Joi.string()
      .required()
      .label("Error: What is the sale price of item "),
    title: Joi.string()
      .required()
      .label(
        "Error: No Spaces; titles can only use letters, numbers, underscores and periods."
      ),
    link: Joi.string()
      .required()
      .label("Error: Link is required"),
    description: Joi.string()
      .required()
      .label("Description is required ")
  };

  handelSelectedStatus = val => {
    this.setState({ affStatus: val.target.textContent });
  };

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  refreshStreams = () => {
    this.setState({
      error: "",
      feedImgFile: null,
      imagePreviewUrl: "",
      recordedStream: null
    });
  };

  handleImageFeed = () => {
    this.refreshStreams();
    let reader = new FileReader();
    let file = this.fileInput.current.files[0];
    let imgSize = this.fileInput.current.files[0].size / 1024 / 1024;
    if (imgSize > 4) {
      this.setState({
        error: "Image size is large, we only accept 4MB currently"
      });
      return;
    }

    reader.onloadend = () => {
      this.setState({
        error: "",
        feedImgFile: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const { classes, ...rest } = this.props;
    const {
      submitDisabled,
      affStatus,
      affStatusOption,
      error,
      successMessage,
      imagePreviewUrl,
      image,
      mobileOpen,
      color
    } = this.state;
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={navlinks}
          logoText={""}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        />
        <div>
          <Card>
            <CardHeader color="primary">
              <h4 className={`${classes.cardTitleWhite} font-size-lg`}>
                Add new Affiliate
              </h4>
            </CardHeader>
            <CardBody>
              <Danger>{error}</Danger>
              <p>{successMessage}</p>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Title"
                    id="title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "title",
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Program"
                    id="affiliate-program"
                    className="font-bold"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "program",
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Commission"
                    id="affiliate-commission"
                    className="font-bold"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      name: "commission",
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Sale Price"
                    id="affiliate-salePrice"
                    className="font-bold"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      name: "salePrice",
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <div className="d-inline-block m-v-md">
                    <CustomDropdown
                      buttonText="Status?"
                      buttonProps={{
                        color: "primary",
                        round: true
                      }}
                      dropdownList={affStatusOption}
                      onClick={this.handelSelectedStatus}
                    />
                  </div>
                  <span className="m-h-md d-inline-block">{affStatus}</span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Link"
                    id="affiliate-link"
                    className="font-bold"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "link",
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel style={{ color: "#AAAAAA" }}>About</InputLabel>
                  <CustomInput
                    labelText="Description"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      name: "description",
                      onChange: this.handleChange,
                      rows: 5
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div className="content-center">
                    <input
                      accept="image/jpeg, image/png"
                      className={`${classes.input} fileInput`}
                      id="imgfile"
                      type="file"
                      onChange={this.handleImageFeed}
                      ref={this.fileInput}
                    />
                    <label
                      htmlFor="imgfile"
                      className="d-block text-center clickable-icon m-h-lg"
                    >
                      Click icon to upload Image &nbsp;
                      <AddPhotoAlternate
                        style={{
                          color: "#000",
                          fontSize: "4em",
                          display: "block",
                          margin: "0 auto"
                        }}
                      />
                    </label>
                  </div>
                  <img
                    src={imagePreviewUrl}
                    alt="user preview"
                    className={`${
                      imagePreviewUrl ? "d-block" : "d-none"
                    } feedImgPreview m-v-md`}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={this.validateForm}
                disabled={submitDisabled}
              >
                Add Affiliate
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    );
  }
}
export default withStyles(dashboardStyle)(AddAffiliate);
