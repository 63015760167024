import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

// core components
import Admin from "layouts/Admin.jsx";
import LoginPage from "layouts/LoginPage.jsx";
import ResetPage from "layouts/ResetPage.jsx";
import UserPage from "layouts/UserPage.jsx";
import CompletedRequestPage from "layouts/CompletedRequestPage.jsx";
import UserCompletedRequestPage from "layouts/UserCompletedRequestPage.jsx";
import UserPendingRequest from "layouts/UserPendingRequestPage.jsx";
import "assets/css/material-dashboard-react.css?v=1.6.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/user/:username" component={UserPage} />
      <Route path="/completed/:username" component={CompletedRequestPage} />
      <Route
        path="/usercompleted/:username"
        component={UserCompletedRequestPage}
      />
      <Route path="/userpendings/:username" component={UserPendingRequest} />
      <Route path="/forgotten" component={ResetPage} />
      <Route path="/" component={Admin} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
