/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
//Joi
import Joi from "joi-browser";
//axios
import axios from "axios";
import configApi from "../services/config.json";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Header from "components/Header/Header.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Popover from "@material-ui/core/Popover";
import Danger from "components/Typography/Danger.jsx";


import Footer from "components/Footer/Footer.jsx";



import loginStyle from "assets/jss/material-dashboard-react/views/loginStyle.jsx";
import image from "assets/img/profile-bg.jpg";
import "../App.css";


class LoginForm extends React.Component {
   anchorElBottom = null;
  constructor(props) {
    super(props);
    this.state = {
      openBottom: false,
      cardAnimaton: "cardHidden",
      useremail: "",
      password: "",
      submitDisabled: false,
      error: ""
    };
  }

  schema = {
    useremail: Joi.string()
      .required()
      .email()
      .label("email value "),
    password: Joi.string()
      .required()
      .label("Password ")
  };

  handleChange = ({ currentTarget: input }) => {
    const formState = { ...this.state };
    formState[input.name] = input.value;
    this.setState(formState);
  };

  handleLoginSubmit = e => {
    e.preventDefault();
    const validatorObj = {
      useremail: this.state.useremail,
      password: this.state.password
    };
    const result = Joi.validate(validatorObj, this.schema);
    const { password, useremail } = this.state;
    this.setState({ error: "" });

    if (result.error !== null)
      return this.setState({ error: result.error.details[0].message });

    this.setState({ submitDisabled: true });
    this.loginApi(useremail, password);
  };

  loginApi() {
    const { useremail, password } = this.state;
    axios
      .post(configApi.dashboardLogin, {
        useremail,
        password
      })
      .then(res => {
        const loginRes = res.data;
        if (!loginRes.success)
          return this.setState({ error: loginRes.message, submitDisabled: false });

        //success response callback
        this.successResponse(res);
      })
      .catch((e) => {
        console.log("🔥", e);
        this.setState({ submitDisabled: false });
      });
  }

  successResponse(res) {
    this.setState({ error: "" });
    const username = res.data.response[0].username;
    localStorage.setItem("useremail", res.data.response[0].email);
    localStorage.setItem("username", username);
    localStorage.setItem("fan_id", res.data.response[0].id);
    localStorage.setItem("picid", res.data.response[0].picture);
    localStorage.setItem("type", res.data.response[0].usertype);
    localStorage.setItem("fab_name", res.data.response[0].name);
    localStorage.setItem("token", res.data.response[0].token);
    localStorage.setItem("bio", res.data.response[0].bio);
    localStorage.setItem("ig_id", res.data.response[0].ig_id);
    localStorage.setItem("twitter_id", res.data.response[0].twitter_id);
    localStorage.setItem("fb_id", res.data.response[0].fb_id);
    localStorage.setItem("shoutrate", res.data.response[0].shoutrate);
    localStorage.setItem("available_slot", res.data.response[0].available_slot);
    localStorage.setItem("secondary_talent", res.data.response[0].secondary_talent);
    localStorage.setItem("primary_talent", res.data.response[0].primary_talent);
    localStorage.setItem("extra_talent", res.data.response[0].extra_talent);//
    localStorage.setItem("u_status", res.data.response[0].active);

    this.props.history.replace('/dashboard');
  }

  handleClosePopover(state) {
    this.setState({
      [state]: false
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true
    });
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
 
  render() {
    const { classes, ...rest } = this.props;
    const { error, submitDisabled } = this.state;
    return (
      <div>
        <Header absolute color="transparent" brand="Fanbies" {...rest} />
        <div
            className={classes.pageHeader}
            style={{
              backgroundImage: "url(" + image + ")",
              backgroundSize: "cover",
              backgroundPosition: "top center"
            }}
          >
          <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={4}>
                  <Card className={classes[this.state.cardAnimaton]}>
                    <form className={classes.form} noValidate="noValidate">
                      <CardHeader color="primary" className={classes.cardHeader}>
                        <h4>Dashboard Login</h4>
                      </CardHeader>
                      <CardBody>
                        <span className="text-center">
                          <Danger>{error}</Danger>
                        </span>
                        <CustomInput
                          labelText="Email..."
                          id="first"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            onChange: this.handleChange,
                            name: "useremail",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  email_outline
                                </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="password"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "password",
                            name: "password",
                            onChange: this.handleChange,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                        <p className="font-size-sm text-center">
                          By clicking on sign up, you agree to
                          <a
                            href="#/"
                            onClick={() => this.handleClickButton("openBottom")}
                          >
                            {" "}
                            terms &amp; conditions
                          </a>
                        </p>
                        <Popover
                          classes={{
                            paper: classes.popover
                          }}
                          open={this.state.openBottom}
                          anchorEl={this.anchorElBottom}
                          anchorReference={"anchorEl"}
                          onClose={() => this.handleClosePopover("openBottom")}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "center"
                          }}
                          transformOrigin={{
                            vertical: "center",
                            horizontal: "center"
                          }}
                        >
                          <h3
                            className={`${
                              classes.popoverHeader
                            } font-bold p-a-md`}
                          >
                            Terms &amp; Conditions
                          </h3>
                          <div
                            className={`${classes.popoverBody} p-a-md p-t-none`}
                          >
                            <h4 className="font-bold">Your Acceptance</h4>
                            By using or visiting the Fanbies website or any
                            Fanbies products, software, data feeds, and services
                            provided to you on, from, or through the Fanbies
                            website (collectively the Service) you signify
                            agreement to (1) these terms and conditions (the Terms
                            of Service), s and also incorporated herein by
                            reference. If you do not agree to any of these terms,
                            the Fanbies Privacy Policy, or the Community
                            Guidelines, please do not use the Service. Although we
                            may attempt to notify you when major changes are made
                            to these Terms of Service, you should periodically
                            review the most up-to-date version. Fanbies may, in
                            its sole discretion, modify or revise these Terms of
                            Service and policies at any time, and you agree to be
                            bound by such modifications or revisions. Nothing in
                            or revisions. Nothing in these Terms of Service shall
                            be deemed to confer any third-party rights or
                            benefits.
                          </div>
                        </Popover>
                      </CardBody>
                      <CardFooter className={classes.cardFooter}>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={this.handleLoginSubmit}
                          disabled={submitDisabled}
                        >
                          Log in
                        </Button>
                        <br />
                      </CardFooter>
                      <a
                        className="d-block text-center m-b-sm font-bold"
                        href="/forgotten"
                      >
                        Forgotten Password
                      </a>
                    </form>
                  </Card>
                </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginStyle)(LoginForm);
