import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import _ from "lodash";
import configApi from "../../services/config.json";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
//core components
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

class Settings extends React.Component {
  state = {
    settingsChecked: [1, 2],
    talentArry: [],
    submitDisabled: false,
    successMsg: "",
    error: "",
    featurduser: "",
    featuredList: [],
    featuredLabel: []
  };

  loadconsoleApi = () => {
    axios
      .all([
        this.getTalentList(),
        this.getConsoleDetail(),
        this.getfeatureduser(),
        this.getactiveusers()
      ])
      .then(res => {
        const talentlistRes = res[0].data;
        const consoledetail = res[1].data;
        const userfeatured = res[2].data;
        const activeList = res[3].data;
        if (!talentlistRes.success)
          return this.setState({ error: talentlistRes.message });
        //Convert String value array to number
        const chekedVal = consoledetail.response[0].val.split(",");
        const val = chekedVal.map(Number);

        //remove None value from option
        _.remove(talentlistRes.response, { label: "None" });
        this.setState({
          talentArry: talentlistRes.response,
          settingsChecked: val,
          featurduser: userfeatured.response[0].name,
          featuredLabel: _.map(activeList.response, "name"),
          featuredList: activeList.response
        });
      })
      .catch(e => {
        console.log("😱", e);
      });
  };

  getTalentList() {
    return axios.post(configApi.getUserTalent);
  }

  getConsoleDetail() {
    return axios.post(configApi.consoledetails);
  }

  getfeatureduser() {
    return axios.post(configApi.getfeatureduser);
  }

  getactiveusers() {
    return axios.post(configApi.getactiveusers);
  }

  handelSelected = val => {
    // get uid
    const selectedId = _.filter(this.state.featuredList, [
      "name",
      val.target.textContent
    ])[0].id;

    axios
      .post(configApi.dashboardupdatefeatureduser, {
        uid: selectedId
      })
      .then(res => {
        const response = res.data;
        if (!response.success)
          return this.setState({ error: response.message });
      })
      .catch(err => {
        console.warn(err);
        this.setState({ submitDisabled: false });
      });
  };

  handleToggle = value => () => {
    const { settingsChecked } = this.state;
    const currentIndex = settingsChecked.indexOf(value);
    const newChecked = [...settingsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      settingsChecked: newChecked
    });
  };

  componentDidMount() {
    this.loadconsoleApi();
  }

  loadOption = items => {
    const { classes } = this.props;
    let item;
    if (items.length > 1) {
      item = items.map((i, key) => {
        return (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                tabIndex={-1}
                onClick={this.handleToggle(i.id)}
                checked={
                  this.state.settingsChecked.indexOf(i.id) !== -1 ? true : false
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked
                }}
              />
            }
            label={i.label}
          />
        );
      });
    }
    return item;
  };

  updateConsole = () => {
    const { settingsChecked } = this.state;
    this.setState({ submitDisabled: true, successMsg: "", error: "" });
    axios
      .post(configApi.updateconsoledetails, {
        settings: settingsChecked,
        jtoken: localStorage.getItem("token")
      })
      .then(res => {
        const resConfirmation = res.data;
        this.setState({ submitDisabled: false });
        if (!resConfirmation.success)
          return this.setState({ error: resConfirmation.message });

        this.setState({ successMsg: resConfirmation.message });
      })
      .catch(e => {
        console.log("😱", e);
        this.setState({ submitDisabled: false });
      });
  };

  render() {
    const { classes } = this.props;
    const {
      talentArry,
      submitDisabled,
      successMsg,
      error,
      featurduser,
      featuredLabel
    } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                Frontend Landing Page Category Settings
              </h4>
            </CardHeader>
            <CardBody>
              <p className="secondary-color">{error}</p>
              <p className="info-color">{successMsg}</p>
              {this.loadOption(talentArry)}
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={this.updateConsole}
                disabled={submitDisabled}
              >
                Update Profile
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Featured User Settings</h4>
            </CardHeader>
            <CardBody>
              Currently Featured User : <b>{featurduser}</b>
              <div>
                <p>Select Talent Badges</p>
                <div className="d-inline-block scrollable-list">
                  <CustomDropdown
                    buttonText="Featured User List"
                    buttonProps={{
                      color: "warning",
                      round: true
                    }}
                    dropdownList={featuredLabel}
                    onClick={this.handelSelected}
                  />
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={this.updateFeatured}
                disabled={submitDisabled}
              >
                Update Profile
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
export default withStyles(checkboxAdnRadioStyle)(Settings);
