/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import axios from "axios";
import configApi from "../services/config.json";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import routes from "routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-1.jpg";
import logo from "assets/img/Fanbies_logo.png";



class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      utype: 1
    };
  }
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  validatToken = () => {
    axios
      .post(configApi.validatetoken, {
        jwtoken: localStorage.getItem("token")
      })
      .then(res => {
        const tokenRes = res.data;
        if (!tokenRes.success)
          return <Redirect to="/login" />

        this.setState({utype: tokenRes.response.utype });
        //redirect
        if (this.props.location.pathname === "/") return this.props.history.replace("/dashboard");
      })
      .catch(e => {
        console.log("🤪",e);
      });
  }
  componentDidMount() {
    if(localStorage.getItem("token") !== null ) this.validatToken();
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() {
    const { utype } = this.state;
    let navlinks;
    if(utype < 2) {
      navlinks = _.filter(routes,["type", "default"]);
    } else {
      navlinks = routes;
    }
     
    const switchRoutes = (
    <Switch>
      {navlinks.map((prop, key) => {
          return (
            <Route
              path={prop.path}
              component={prop.component}
              key={key}
            />
          );
        })
      }
    </Switch>
    );
    if(localStorage.getItem("token") === null ) return <Redirect to="/login" />
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={navlinks}
          logoText={""}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Navbar
            routes={navlinks}
            handleDrawerToggle={this.handleDrawerToggle}
            usertype={utype}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {this.getRoute() ? <Footer /> : null}
          {/* <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          /> */}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
