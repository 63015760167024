// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
//import Unarchive from "@material-ui/icons/Unarchive";
//import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import Settings from "views/Dashboard/Settings.jsx";
import NonFamousPage from "views/Dashboard/NonfamousProfile.jsx";
import FamousPage from "views/Dashboard/FamousProfile.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import AddUser from "layouts/AddUserPage.jsx";
//import TableList from "views/TableList/TableList.jsx";
import LogOutPage from "layouts/Logout.jsx";
import AddAffiliatePage from "views/Dashboard/addAffiliate.jsx";
import AffiliateProgramsPage from "views/Dashboard/listAffiliate.jsx";
import ListOfAllAffiliatePage from "views/Dashboard/listalluserAffiliate.jsx";
// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    type: "default"
  },
  {
    path: "/user",
    name: "My Profile",
    icon: Person,
    component: UserProfile,
    type: "default"
  },
  {
    path: "/setting",
    name: "Settings",
    icon: "settings",
    component: Settings,
    type: "super"
  },
  {
    path: "/adduser",
    name: "Add User",
    icon: "person_add",
    component: AddUser,
    type: "super"
  },
  {
    path: "/normaluser",
    name: "Fanbies Users",
    icon: "explore",
    component: NonFamousPage,
    type: "super"
  },
  {
    path: "/famoususer",
    name: "Famous Users",
    icon: "supervised_user_circle",
    component: FamousPage,
    type: "super"
  },
  {
    path: "/affiliateRegistered",
    name: "Add Affiliates",
    icon: "shop",
    component: AddAffiliatePage,
    type: "super"
  },
  {
    path: "/affiliatesPrograms",
    name: "Affiliates Programs",
    icon: "verified",
    component: AffiliateProgramsPage,
    type: "super"
  },
  {
    path: "/affiliatesProfiles",
    name: "Affiliates Users",
    icon: "loyalty",
    component: ListOfAllAffiliatePage,
    type: "super"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "toggle_off",
    component: LogOutPage,
    type: "default"
  }
];

export default dashboardRoutes;
