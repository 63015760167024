import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import SlowMotionVideo from "@material-ui/icons/SlowMotionVideo";
import NewReleases from "@material-ui/icons/NewReleases";
// import BugReport from "@material-ui/icons/BugReport";
import MonetizationOnOutlined from "@material-ui/icons/MonetizationOnOutlined";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Table from "components/Table/Table.jsx";
// import Tasks from "components/Tasks/Tasks.jsx";
// import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
// import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import axios from "axios";
import configApi from "../../services/config.json";

//import { bugs, website, server } from "variables/general.jsx";

import { dailySalesChart, completedTasksChart } from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Dashboard extends React.Component {
  state = {
    value: 0,
    totaluser: 0,
    totalceleb: 0,
    totalPending: 0,
    totalCompleted: 0,
    totalAffiliatePrograms: 0,
    totalAffiliateUsers: 0,
    usertype: parseInt(localStorage.getItem("type"), 10),
    uid: parseInt(localStorage.getItem("fan_id"), 10),
    myTotalPending: 0,
    myTotalCompleted: 0,
    isLoaded: false
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    if (this.state.usertype === 1) return this.loadmydasboardApi();

    this.loadDashboardApi();
  }

  dashboardTotalUserApi = () => {
    return axios.post(configApi.gettotaluser);
  };

  dashboardTotalCelebApi = () => {
    return axios.post(configApi.gettotalcelebs);
  };

  dashboardTotalAffiliatePrograms = () => {
    return axios.post(configApi.gettotalaffiliateprograms);
  };

  dashboardTotalAffiliateUsers = () => {
    return axios.post(configApi.gettotalaffiliateusers);
  };

  dashboardTotalCompleteRequestApi = () => {
    return axios.post(configApi.gettotalrequest, { type: "completed" });
  };

  dashboardTotalPendingApi = () => {
    return axios.post(configApi.gettotalrequest, { type: "created" });
  };

  getMyTotalCompleteRequestApi = () => {
    //console.log("this.state.uid", this.state.uid);
    return axios.post(configApi.getmytotalrequest, {
      type: "completed",
      uid: this.state.uid,
      jtoken: localStorage.getItem("token")
    });
  };

  getMyTotalPendingApi = () => {
    return axios.post(configApi.getmytotalrequest, {
      type: "created",
      uid: this.state.uid,
      jtoken: localStorage.getItem("token")
    });
  };

  //User Api call
  loadmydasboardApi = () => {
    axios
      .all([this.getMyTotalCompleteRequestApi(), this.getMyTotalPendingApi()])
      .then(res => {
        const resMyCompleted = res[0].data;
        const resMyPending = res[1].data;
        if (!resMyCompleted.success)
          return this.setState({ error: resMyCompleted.message });

        this.setState({
          myTotalPending: resMyPending.response,
          myTotalCompleted: resMyCompleted.response,
          isLoaded: true
        });
      })
      .catch();
  };

  //Admin Api Call
  loadDashboardApi = () => {
    axios
      .all([
        this.dashboardTotalUserApi(),
        this.dashboardTotalCelebApi(),
        this.dashboardTotalCompleteRequestApi(),
        this.dashboardTotalPendingApi(),
        this.dashboardTotalAffiliatePrograms(),
        this.dashboardTotalAffiliateUsers()
      ])
      .then(res => {
        const resTotalUser = res[0].data;
        const resTotalCeleb = res[1].data;
        const resCompleted = res[2].data;
        const resPending = res[3].data;
        const resAffPrograms = res[4].data;
        const resAffUsers = res[5].data;

        if (!resTotalUser.success)
          return this.setState({ error: resTotalUser.message });

        this.setState({
          totaluser: resTotalUser.response,
          totalceleb: resTotalCeleb.response,
          totalPending: resPending.response,
          totalCompleted: resCompleted.response,
          totalAffiliatePrograms: resAffPrograms.response,
          totalAffiliateUsers: resAffUsers.response,
          isLoaded: true
        });
      })
      .catch();
  };

  render() {
    const { classes } = this.props;
    const username = localStorage.getItem("username");
    const {
      isLoaded,
      totaluser,
      totalceleb,
      totalCompleted,
      totalPending,
      usertype,
      myTotalPending,
      myTotalCompleted,
      totalAffiliatePrograms,
      totalAffiliateUsers
    } = this.state;
    return (
      <div>
        {!isLoaded ? (
          <div className="text-center pos-rel">
            <div className="overlay transparent">
              <div className="lds-ripple pos-ab top-0">
                <div />
                <div />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {usertype >= 2 ? (
              <React.Fragment>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <Card>
                      <CardHeader color="info" stats icon>
                        <CardIcon color="info">
                          <Accessibility />
                        </CardIcon>
                        <p className={classes.cardCategory}>Users</p>
                        <h3 className={classes.cardTitle}>{totaluser}</h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <Update />
                          Just Updated +245
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <Card>
                      <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                          <MonetizationOnOutlined />
                        </CardIcon>
                        <p className={classes.cardCategory}>Revenue</p>
                        <h3 className={classes.cardTitle}>$34,245</h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <DateRange />
                          Last 24 Month
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <Card>
                      <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                          <RecordVoiceOver />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          Total Celebrity Fanbies Users
                        </p>
                        <h3 className={classes.cardTitle}>{totalceleb}</h3>
                      </CardHeader>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <Card>
                      <CardHeader stats icon>
                        <CardIcon color="info">
                          <Store />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          Total Affiliate Programs
                        </p>
                        <h3 className={classes.cardTitle}>
                          {totalAffiliatePrograms}
                        </h3>
                      </CardHeader>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <Card>
                      <CardHeader stats icon>
                        <CardIcon color="success">
                          <ThumbUpAltOutlined />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          Active Affiliate Joined
                        </p>
                        <h3 className={classes.cardTitle}>
                          {totalAffiliateUsers}
                        </h3>
                      </CardHeader>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <Card>
                      <CardHeader color="danger" stats icon>
                        <CardIcon color="danger">
                          <NewReleases />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          Total Pending Request
                        </p>
                        <h3 className={classes.cardTitle}>{totalPending}</h3>
                      </CardHeader>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <Card>
                      <CardHeader color="rose" stats icon>
                        <CardIcon color="rose">
                          <SlowMotionVideo />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          Total Completed Request
                        </p>
                        <h3 className={classes.cardTitle}>{totalCompleted}</h3>
                      </CardHeader>
                    </Card>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Card chart>
                      <CardHeader color="success">
                        <ChartistGraph
                          className="ct-chart"
                          data={dailySalesChart.data}
                          type="Line"
                          options={dailySalesChart.options}
                          listener={dailySalesChart.animation}
                        />
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Daily Sales</h4>
                        <p className={classes.cardCategory}>
                          <span className={classes.successText}>
                            <ArrowUpward
                              className={classes.upArrowCardCategory}
                            />
                            55%
                          </span>{" "}
                          increase in today sales.
                        </p>
                      </CardBody>
                      <CardFooter chart>
                        <div className={classes.stats}>
                          <AccessTime /> updated 4 minutes ago
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Card chart>
                      <CardHeader color="danger">
                        <ChartistGraph
                          className="ct-chart"
                          data={completedTasksChart.data}
                          type="Line"
                          options={completedTasksChart.options}
                          listener={completedTasksChart.animation}
                        />
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Completed Tasks</h4>
                        <p className={classes.cardCategory}>
                          Last Campaign Performance
                        </p>
                      </CardBody>
                      <CardFooter chart>
                        <div className={classes.stats}>
                          <AccessTime /> campaign sent 2 days ago
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <CardHeader color="danger" stats icon>
                        <CardIcon color="danger">
                          <NewReleases />
                        </CardIcon>
                        <div className="min-height-sm">
                          <p className={classes.cardCategory}>
                            Total Pending Request
                          </p>
                          <h3 className={classes.cardTitle}>
                            {myTotalPending}
                          </h3>
                        </div>
                      </CardHeader>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <CardHeader color="rose" stats icon>
                        <CardIcon color="rose">
                          <SlowMotionVideo />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          Total Completed Request
                        </p>
                        <h3 className={classes.cardTitle}>
                          {myTotalCompleted}
                        </h3>
                      </CardHeader>
                      <CardFooter>
                        <Link
                          to={{
                            pathname: `/completed/${username}`
                          }}
                        >
                          View Your Completed Request
                        </Link>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            )}
            {/* <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomTabs
                  title="Tasks:"
                  headerColor="primary"
                  tabs={[
                    {
                      tabName: "Bugs",
                      tabIcon: BugReport,
                      tabContent: (
                        <Tasks
                          checkedIndexes={[0, 3]}
                          tasksIndexes={[0, 1, 2, 3]}
                          tasks={bugs}
                        />
                      )
                    },
                    {
                      tabName: "Website",
                      tabIcon: Code,
                      tabContent: (
                        <Tasks
                          checkedIndexes={[0]}
                          tasksIndexes={[0, 1]}
                          tasks={website}
                        />
                      )
                    },
                    {
                      tabName: "Server",
                      tabIcon: Cloud,
                      tabContent: (
                        <Tasks
                          checkedIndexes={[1]}
                          tasksIndexes={[0, 1, 2]}
                          tasks={server}
                        />
                      )
                    }
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="warning">
                    <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
                    <p className={classes.cardCategoryWhite}>
                      New employees on 15th September, 2016
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Table
                      tableHeaderColor="warning"
                      tableHead={["ID", "Name", "Salary", "Country"]}
                      tableData={[
                        ["1", "Dakota Rice", "$36,738", "Niger"],
                        ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                        ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                        ["4", "Philip Chaney", "$38,735", "Korea, South"]
                      ]}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer> */}
          </div>
        )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
