import React from "react";
import axios from "axios";
import ReactPlayer from "react-player/file";

import configApi from "../../services/config.json";
import Joi from "joi-browser";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Danger from "components/Typography/Danger.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Success from "components/Typography/Success.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VideoLibrary from "@material-ui/icons/VideoLibrary";
import Close from "@material-ui/icons/Close";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      password: "",
      repassword: "",
      submitDisabled: false,
      isLoading: false,
      talentArry: {},
      name: localStorage.getItem("fab_name"),
      username: localStorage.getItem("username"),
      profilepic: localStorage.getItem("picid"),
      usertype: localStorage.getItem("type"),
      useremail: localStorage.getItem("useremail"),
      bio: localStorage.getItem("bio"),
      requestRate: localStorage.getItem("shoutrate"),
      availableSlot: localStorage.getItem("available_slot"),
      fbtag: localStorage.getItem("fb_id"),
      twittertag: localStorage.getItem("twitter_id"),
      igtag: localStorage.getItem("ig_id"),
      userstatus: localStorage.getItem("u_status"),
      avaiableStatusArry: ["UnAvailable", "Coming Soon", "Available"],
      feedLiked: false,
      modal: false,
      modaltype: "",
      confirmationMessage: "",
      input__comment: "",
      feedImgFile: "",
      imagePreviewUrl: "",
      recordedStream: null,
      isPlaying: false,
      feedsArray: [],
      modalItem: "",
      linkvisible: false,
      linktitle: "",
      linkref: "",
      mylinksArray: []
    };
    this.fileInput = React.createRef();
    this.videoInput = React.createRef();
    this.handleVideoPlay = this.handleVideoPlay.bind(this);
  }

  handleLinkToggle = () => {
    this.setState(state => ({ linkvisible: !state.linkvisible }));
  };

  handleTitleChange = (e, item) => {
    const title = e.currentTarget.value;
    const linkObj = this.state.mylinksArray;
    linkObj.find(i => {
      if (i.id === item.id) {
        i.title = title;
      }
    });
    this.setState({ mylinksArray: linkObj });
  };

  handleLinkRefChange = (e, item) => {
    const ref = e.currentTarget.value;
    const linkObj = this.state.mylinksArray;
    linkObj.find(i => {
      if (i.id === item.id) {
        i.link_ref = ref;
      }
    });
    this.setState({ mylinksArray: linkObj });
  };

  handleUpdate = item => {
    axios
      .post(configApi.updatelink, {
        jtoken: localStorage.getItem("token"),
        item
      })
      .then(res => {
        const feedData = res.data;
        if (!feedData.success) return;
      })
      .catch(() => {
        //sentry
        //  console.log("😱", e);
      });
  };

  loadfeeds() {
    axios
      .post(configApi.getuserfeeds, {
        jtoken: localStorage.getItem("token")
      })
      .then(res => {
        const feedData = res.data;
        if (!feedData.success) return;
        this.setState({
          feedsArray: feedData.response
        });
      })
      .catch(() => {
        //sentry
        //console.log("😱", e);
      });
  }

  loadmylinks() {
    axios
      .post(configApi.getuserlinks, {
        username: localStorage.getItem("username")
      })
      .then(res => {
        const linksData = res.data;
        if (!linksData.success) return;
        this.setState({
          mylinksArray: linksData.response
        });
      })
      .catch(() => {
        //sentry
        //console.log("😱", e);
      });
  }

  handleToggleObjUpdate = (e, item, checked) => {
    const linkObj = this.state.mylinksArray;
    linkObj.find(i => {
      if (i.id === item.id) {
        i.visible = !checked;
      }
    });

    this.setState({ mylinksArray: linkObj });
    this.handleUpdate(item);
  };

  componentDidMount() {
    this.loadfeeds();
    this.loadmylinks();
  }

  handelSelectedStatus = val => {
    let valueSet;
    if (val.target.textContent === "UnAvailable") {
      valueSet = 1;
    } else if (val.target.textContent === "Coming Soon") {
      valueSet = 2;
    } else {
      valueSet = 3;
    }

    this.setState({ userstatus: valueSet });
  };

  handelSelectedPrimaryTalent = val => {
    this.setState({ primaryTalent: val.target.textContent });
  };

  handelSelectedSecondaryTalent = val => {
    this.setState({ secondaryTalent: val.target.textContent });
  };

  handelSelectedExtraTalent = val => {
    this.setState({ extraTalent: val.target.textContent });
  };

  handleChange = ({ currentTarget: input }) => {
    const formState = { ...this.state };
    formState[input.name] = input.value;
    this.setState(formState);
  };

  //Feeds
  refreshStreams = () => {
    this.setState({
      error: "",
      feedImgFile: "",
      imagePreviewUrl: "",
      recordedStream: null
    });
  };

  handleImageFeed = () => {
    this.refreshStreams();
    let reader = new FileReader();
    let file = this.fileInput.current.files[0];
    let imgSize = this.fileInput.current.files[0].size / 1024 / 1024;
    if (imgSize > 4) {
      this.setState({
        error: "Image size is large, we only accept 4MB currently"
      });
      return;
    }

    reader.onloadend = () => {
      this.setState({
        error: "",
        feedImgFile: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  handleVideoFeed = () => {
    let _this = this;
    _this.refreshStreams();
    this.setState({ error: "" });
    let previewVideo = document.querySelector("video#preview");
    previewVideo.preload = "metadata";

    previewVideo.onloadedmetadata = () => {
      window.URL.revokeObjectURL(previewVideo.src);
      if (previewVideo.duration > 1820) {
        previewVideo.srcObject = null;
        previewVideo.src = null;
        return _this.setState({
          uploadError: "Video duration should less than 30 mintues"
        });
      }
    };

    previewVideo.srcObject = null;
    previewVideo.src = null;
    previewVideo.src = window.URL.createObjectURL(
      this.videoInput.current.files[0]
    );
    this.setState({ recordedStream: this.videoInput.current.files[0] });
    previewVideo.controls = true;
    previewVideo.play();
  };

  handleFeedSubmit = e => {
    e.preventDefault();
    const { recordedStream, feedImgFile, caption } = this.state;
    if (localStorage.getItem("token") == null) return;

    let data = new FormData();

    let vidfile = recordedStream;
    let postType = vidfile !== null ? "video" : "image";
    let uploadfile = vidfile !== null ? recordedStream : feedImgFile;

    data.append("file", uploadfile);
    data.set("type", postType);
    data.set("title", caption);

    //localstorage
    data.set("token", localStorage.getItem("token"));
    data.set("useremail", localStorage.getItem("useremail"));
    data.set("uid", localStorage.getItem("fan_id"));
    data.set("ownername", localStorage.getItem("username"));

    // remove preview with a loading screen
    let previewVideo = document.querySelector("video#preview");
    previewVideo.srcObject = null;
    previewVideo.src = null;
    //User should be able to submit one recording per session.
    this.setState({
      confirmationSent: true,
      confirmationMessage: "Posting feed..."
    });
    //WRITE API HERE...
    setTimeout(() => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data,
        url: configApi.uploadfeed
      };
      axios(options).then(res => {
        this.setState({
          confirmationMessage: res.message,
          confirmationSent: false
        });
        this.refreshStreams();
      });
    }, 1000);
  };
  // add link
  addLinkEntry = () => {
    const { linkref, linktitle, linkvisible } = this.state;
    axios.post(configApi.createlink, {
      linkref,
      linktitle,
      linkvisible,
      jtoken: localStorage.getItem("token")
    });
  };
  // edit profile
  editProfile = () => {
    const {
      password,
      name,
      fbtag,
      twittertag,
      igtag,
      bio,
      availableSlot,
      userstatus,
      useremail,
      requestRate
    } = this.state;

    axios
      .post(configApi.dashboardupdateprofile, {
        fbtag,
        twittertag,
        igtag,
        bio,
        name,
        password,
        primary_talent: "",
        secondary_talent: "",
        extra_talent: "",
        availableSlot,
        userstatus,
        requestRate,
        useremail,
        jtoken: localStorage.getItem("token")
      })
      .then(res => {
        const updateRes = res.data;
        this.setState({ submitDisabled: false });
        if (!updateRes.success)
          return this.setState({ error: updateRes.message });
        // update localstorage
        localStorage.setItem("fab_name", name);
        localStorage.setItem("bio", bio);
        localStorage.setItem("fb_id", fbtag);
        localStorage.setItem("twitter_id", twittertag);
        localStorage.setItem("ig_id", igtag);
        localStorage.setItem("primary_talent", "");
        localStorage.setItem("secondary_talent", "");
        localStorage.setItem("extra_talent", "");
        localStorage.setItem("available_slot", availableSlot);
        localStorage.setItem("u_status", userstatus);

        localStorage.setItem("shoutrate", requestRate);
        localStorage.setItem("useremail", useremail);

        window.location.reload();
      })
      .catch(() => {
        // console.warn(err);
        this.setState({ submitDisabled: false });
      });
  };

  validateForm = e => {
    e.preventDefault();
    const validatorObj = {
      repassword: this.state.repassword,
      password: this.state.password,
      availableSlot: this.state.availableSlot,
      useremail: this.state.useremail,
      requestRate: this.state.requestRate,
      name: this.state.name
    };
    const result = Joi.validate(validatorObj, this.schema);
    this.setState({ error: "" });
    if (result.error !== null)
      return this.setState({ error: result.error.details[0].message });

    this.setState({ submitDisabled: true });
    this.editProfile();
  };

  handleClickOpen(data, modaltype) {
    this.setState({ modaltype, modal: true, modalItem: data });
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState({ modaltype: "", modalItem: "" });
    this.setState(x);
    this.refreshStreams();
  }

  handlePicUpdate = () => {
    //imageChangerForm
    this.setState({ isLoading: true });
    let data = new FormData();
    data.append("file", this.fileInput.current.files[0]);
    data.set("id", localStorage.getItem("fan_id"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data,
      url: configApi.updatepic
    };
    axios(options)
      .then(res => {
        const uRes = res.data;
        this.setState({
          profilepic: uRes.response,
          isLoading: false
        });
        localStorage.setItem("picid", uRes.response);
      })
      .catch(() => {
        //console.log("😱", e);
        this.setState({ isLoading: false });
      });
  };

  schema = {
    password: Joi.string()
      .required()
      .regex(/^[a-zA-Z0-9]{6,30}$/)
      .label("Password should be length 6 - 30 with multiple char. "),
    repassword: Joi.string()
      .valid(Joi.ref("password"))
      .label("Confirm Password is not equal to Password "),
    availableSlot: Joi.number()
      .integer()
      .min(0)
      .max(99)
      .required()
      .label("Available Slot should be number "),
    useremail: Joi.string()
      .required()
      .email({ minDomainAtoms: 2 })
      .label("Email value "),
    name: Joi.string()
      .min(3)
      .max(99)
      .required()
      .label("Name value "),
    requestRate: Joi.number()
      .integer()
      .min(0)
      .max(2999)
      .required()
      .label("Rate should be number ")
  };

  linkSchema = {
    linktitle: Joi.string()
      .min(3)
      .max(99)
      .regex(/^\s*\w+(?:[^\w,]+\w+)*[^,\w]*$/)
      .required()
      .label("Title"),
    linkref: Joi.string()
      .regex(
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      )
      .required()
      .label("Link ")
  };

  renderDateStamp(stamp) {
    // Convert timestamp to milliseconds
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    var date = new Date(stamp * 1000);

    // Year
    var year = date.getFullYear();

    // Month
    var month = months_arr[date.getMonth()];

    // Day
    var day = date.getDate();

    // Display date time in MM-dd-yyyy h:m:s format
    const convdataTime = `${month}-${day}-${year}`;

    return convdataTime;
  }

  handleVideoPlay = index => {
    const videoELm = index.target;
    const { isPlaying } = this.state;
    this.setState(
      {
        isPlaying: !this.state.isPlaying
      },
      () => {
        if (!isPlaying) {
          videoELm.play();
        } else {
          videoELm.pause();
        }
      }
    );
  };

  deletefeed = i => {
    axios
      .post(configApi.deletefeed, {
        jwtoken: localStorage.getItem("token"),
        id: i.id,
        assettype: i.type,
        video_link: i.video_link,
        img_link: i.img_link
      })
      .then(res => {
        const feedData = res.data;
        if (!feedData.success) return;

        window.location.reload();
      })
      .catch(() => {
        //sentry
        // console.log("😱", e);
      });
  };

  deletelinkbyid = id => {
    if (id == null) return;

    axios
      .post(configApi.deletelinkbyid, {
        jtoken: localStorage.getItem("token"),
        id: id
      })
      .then(res => {
        const linkData = res.data;
        if (!linkData.success) return;

        window.location.reload();
      })
      .catch(() => {
        //sentry
        //console.log("😱", e);
      });
  };

  validateLinkForm = e => {
    e.preventDefault();
    const validatorObj = {
      linktitle: this.state.linktitle,
      linkref: this.state.linkref
    };
    const result = Joi.validate(validatorObj, this.linkSchema);
    this.setState({ error: "" });
    if (result.error !== null)
      return this.setState({ error: result.error.details[0].message });

    this.setState({ submitDisabled: true });
    this.addLinkEntry();
  };

  validateEditLinkForm = (e, i) => {
    e.preventDefault();
    const validatorObj = {
      linktitle: i.title,
      linkref: i.link_ref
    };
    const result = Joi.validate(validatorObj, this.linkSchema);
    this.setState({ error: "" });
    if (result.error !== null)
      return this.setState({ error: result.error.details[0].message });

    //this.setState({ submitDisabled: true });
    this.handleUpdate(i);
  };

  renderLinks = items => {
    const { classes } = this.props;
    const itemlist = items.map((item, i) => {
      return (
        <Card key={i}>
          <CardBody className="p-a-none">
            <GridContainer>
              <div className="m-h-md width-50">
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Title"
                    id="title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "linktitle",
                      onChange: e => this.handleTitleChange(e, item),
                      value: `${item.title}`,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={`grey-icon font-size-sm`}>
                            create
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Url"
                    id="linkref"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "linkref",
                      onChange: e => this.handleLinkRefChange(e, item),
                      value: `${item.link_ref}`,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={`grey-icon font-size-sm`}>
                            create
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
              </div>
              <GridItem xs={5} sm={5} md={5}>
                <Button
                  simple
                  color="primary"
                  onClick={e => this.validateEditLinkForm(e, item)}
                >
                  <Icon className={`font-size-md`}>save</Icon>
                </Button>
                <FormControlLabel
                  className="m-h-lg"
                  control={
                    <Switch
                      checked={item.visible}
                      onChange={evt =>
                        this.handleToggleObjUpdate(evt, item, item.visible)
                      }
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar
                      }}
                    />
                  }
                  classes={`font-bold`}
                  label="Hide link?"
                />
                <Button
                  simple
                  color="danger"
                  onClick={() => this.deletelinkbyid(item.id)}
                >
                  <Icon className={`font-size-md`}>delete</Icon>
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      );
    });
    return itemlist;
  };

  renderFeed = items => {
    const itemlist = items.map((item, i) => {
      return (
        <Card key={i}>
          <CardBody className="p-a-none">
            {item.type === "video" ? (
              <>
                <ReactPlayer
                  controls={true}
                  light={item.img_link}
                  url={item.video_link}
                  width="100%"
                  height="307px"
                  volume={1}
                  pip={false}
                  muted={true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload noplaybackrate",
                        disablePictureInPicture: "true"
                      }
                    }
                  }}
                />
                {/* <div
                  onClick={this.handleVideoPlay}
                  className="video-container width-100 pos-rel d-inline-block"
                >
                  <video
                    className="customVideo"
                    preload="none"
                    playsInline
                    height="307"
                    poster={item.img_link}
                  >
                    <source src={item.video_link} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  
                </div>
                <div className="video-playBtn pos-ab d-block">
                  <PlayCircleOutline
                    style={{ color: "#FFFFFF", fontSize: "3rem" }}
                    onClick={()=> console.log('HELLO')}
                  />
                </div> */}
              </>
            ) : (
              <img src={item.img_link} width="100%" alt="feeds fanbies" />
            )}
          </CardBody>
          <CardFooter className="feeds__footer">
            <Button
              color="danger"
              onClick={() => this.handleClickOpen(item, "deletefeed")}
            >
              Delete feed
            </Button>
            <div className="width-100">
              <span className="floatRight pos-rel top-5">
                {this.renderDateStamp(item.date)}
              </span>
              <p className="feed_title">{item.title}</p>
            </div>
          </CardFooter>
        </Card>
      );
    });
    return itemlist;
  };

  render() {
    const { classes } = this.props;
    const {
      submitDisabled,
      error,
      name,
      username,
      profilepic,
      useremail,
      bio,
      requestRate,
      availableSlot,
      isLoading,
      avaiableStatusArry,
      confirmationMessage,
      confirmationSent,
      modal,
      modaltype,
      imagePreviewUrl,
      recordedStream,
      feedsArray,
      mylinksArray,
      modalItem
    } = this.state;
    //
    const userstatus = parseInt(this.state.userstatus, 10);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                {isLoading ? (
                  <div className="text-center pos-rel">
                    <div className="overlay transparent">
                      <div className="lds-ripple pos-ab top-0">
                        <div />
                        <div />
                      </div>
                    </div>
                  </div>
                ) : (
                  " "
                )}
                <input
                  accept="image/*"
                  className={`${classes.input} fileInput`}
                  id="profilepic"
                  multiple
                  type="file"
                  onChange={this.handlePicUpdate}
                  ref={this.fileInput}
                />
                <label className="clickable-icon" htmlFor="profilepic">
                  <img
                    src={profilepic}
                    alt={`${name} profile pic`}
                    className={`img-align-center profileImg`}
                  />
                </label>
              </CardAvatar>
              <CardBody profile>
                <h6 className={classes.cardCategory}>{name}</h6>
                <p className={classes.description}>{bio}</p>
                <Button
                  color="primary"
                  round
                  onClick={() =>
                    this.props.history.replace(`/completed/${username}`)
                  }
                >
                  Your Completed Request
                </Button>
                <Button
                  round
                  onClick={() => this.handleClickOpen("modal", "createfeed")}
                >
                  Post a Feed
                </Button>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={`${classes.cardTitleWhite} font-size-lg`}>
                  Edit Profile
                </h4>
              </CardHeader>
              <CardBody>
                <Danger>{error}</Danger>
                <p>Read only content</p>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Username"
                      id="username-disabled"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        type: "text",
                        value: `${username}`
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <p>Editable content</p>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      id="email-address"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        name: "useremail",
                        onChange: this.handleChange,
                        value: `${useremail}`
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Your Request Rate($)"
                      id="shoutrate"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        name: "requestRate",
                        onChange: this.handleChange,
                        value: `${requestRate}`
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Name"
                      id="common-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        name: "name",
                        onChange: this.handleChange,
                        value: `${name}`
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Your Available Slot"
                      id="slots"
                      className="font-bold"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        name: "availableSlot",
                        onChange: this.handleChange,
                        value: `${availableSlot}`
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <div className="d-inline-block m-v-md">
                      <CustomDropdown
                        buttonText="Your Status online as:"
                        buttonProps={{
                          color: "primary",
                          round: true
                        }}
                        dropdownList={avaiableStatusArry}
                        onClick={this.handelSelectedStatus}
                      />
                    </div>
                    <span className="m-h-md d-inline-block">
                      {userstatus === 1 ? "Unavailable" : ""}
                      {userstatus === 2 ? "Coming Soon" : ""}
                      {userstatus === 3 ? "Available" : ""}
                    </span>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        name: "password",
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Confirm Password"
                      id="repassword"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        name: "repassword",
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  onClick={this.validateForm}
                  disabled={submitDisabled}
                >
                  Update Profile
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader>
                <h4 className={`font-size-lg`}>Create a Link</h4>
                <Danger>{error}</Danger>
              </CardHeader>
              <CardBody>
                <Card>
                  <GridContainer>
                    <div className="m-h-md width-50">
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Title"
                          id="title"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            name: "linktitle",
                            onChange: this.handleChange,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={`grey-icon font-size-sm`}>
                                  create
                                </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Url"
                          id="linkref"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            name: "linkref",
                            onChange: this.handleChange,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={`grey-icon font-size-sm`}>
                                  create
                                </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                      </GridItem>
                    </div>
                    <GridItem xs={5} sm={5} md={5}>
                      <Button
                        simple
                        color="primary"
                        onClick={this.validateLinkForm}
                      >
                        <Icon className={`font-size-md`}>save</Icon>
                      </Button>
                      <FormControlLabel
                        className="m-h-lg"
                        control={
                          <Switch
                            checked={this.state.linkvisible}
                            onChange={event =>
                              this.handleLinkToggle(event.target.checked)
                            }
                            value="checkedA"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={`font-bold`}
                        label="Hide link?"
                      />
                    </GridItem>
                  </GridContainer>
                </Card>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={5}>
            <Card>
              <CardHeader>
                <h4 className={`font-size-lg`}>My Links</h4>
                <Danger>{error}</Danger>
              </CardHeader>
              <CardBody>
                {mylinksArray.length ? (
                  this.renderLinks(mylinksArray)
                ) : (
                  <p>You have no created links</p>
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={11}>
            <h2>Feeds</h2>
            {feedsArray.length ? (
              this.renderFeed(feedsArray)
            ) : (
              <p>NO FEED Available</p>
            )}
          </GridItem>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            {modaltype === "deletefeed" ? (
              <DialogContent
                id="modal-slide-description"
                className={`${classes.modalBody} p-a-none`}
              >
                <Button
                  onClick={() => this.deletefeed(modalItem)}
                  round
                  color="danger"
                >
                  Confirm Delete
                </Button>
              </DialogContent>
            ) : (
              <DialogContent
                id="modal-slide-description"
                className={`${classes.modalBody} p-a-none`}
              >
                <div
                  className={` ${
                    error || confirmationMessage
                      ? "p-a-md text-center dialog-message"
                      : null
                  }`}
                >
                  <Danger>{error}</Danger>
                  <Success>{confirmationMessage}</Success>
                </div>
                {confirmationSent === true ? (
                  <>
                    <div className="min-height-sm text-center">
                      <div className="overlay transparent">
                        <div className="lds-ripple pos-ab top-0">
                          <div />
                          <div />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="p-a-md">
                    <CustomInput
                      labelText="Post Captions...max 140"
                      id="caption"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        name: "caption",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              create
                            </Icon>
                          </InputAdornment>
                        )
                      }}
                    />
                    <div className="content-center">
                      <input
                        accept="image/jpeg, image/png"
                        className={`${classes.input} fileInput`}
                        id="imgfile"
                        type="file"
                        onChange={this.handleImageFeed}
                        ref={this.fileInput}
                      />
                      <label
                        htmlFor="imgfile"
                        className="d-block text-center clickable-icon m-h-lg"
                      >
                        Click icon to upload Image &nbsp;
                        <AddPhotoAlternate
                          style={{
                            color: "#000",
                            fontSize: "4em",
                            display: "block",
                            margin: "0 auto"
                          }}
                        />
                      </label>
                      <input
                        accept="video/mp4"
                        className={`${classes.input} fileInput`}
                        id="videoInput"
                        type="file"
                        onChange={this.handleVideoFeed}
                        ref={this.videoInput}
                      />
                      <label
                        htmlFor="videoInput"
                        className="d-block text-center clickable-icon upload-video-label m-h-lg"
                      >
                        Click icon to upload MP4 Video &nbsp;
                        <VideoLibrary
                          style={{
                            color: "#000",
                            fontSize: "4em",
                            display: "block",
                            margin: "0 auto"
                          }}
                        />
                      </label>
                    </div>
                    <video
                      id="preview"
                      className={recordedStream ? "d-block" : "d-none"}
                      height="200"
                      width="250"
                      playsInline
                    />
                    <img
                      src={imagePreviewUrl}
                      alt={"user preview"}
                      className={`${
                        imagePreviewUrl ? "d-block" : "d-none"
                      } feedImgPreview m-v-md`}
                    />
                    {imagePreviewUrl || recordedStream ? (
                      <>
                        <IconButton
                          className={`floatLeft p-a-none m-a-sm border-solid-md secondary-color border-round`}
                          key="remove"
                          aria-label="Remove"
                          onClick={() => this.refreshStreams()}
                        >
                          <Close />
                        </IconButton>
                        <Button
                          disabled={
                            imagePreviewUrl || recordedStream ? false : true
                          }
                          color="primary"
                          className="floatRight"
                          onClick={e => this.handleFeedSubmit(e)}
                          round
                        >
                          Post
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </DialogContent>
            )}
          </Dialog>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(UserProfile);
