import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import configApi from "../../services/config.json";
import Popover from "@material-ui/core/Popover";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuList from "@material-ui/core/MenuList";
// import Grow from "@material-ui/core/Grow";
// import Paper from "@material-ui/core/Paper";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
// import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

class HeaderLinks extends React.Component {
  anchorElBottom = null;
  state = {
    open: false,
    openBottom: false,
    searchterm: "",
    listusers: [],
    searchResultMessage: ""
  };
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handleClosePopover(state) {
    this.setState({
      [state]: false
    });
  }

  handleChange = ({ currentTarget: input }) => {
    const formState = { ...this.state };
    formState[input.name] = input.value;
    this.setState(formState);
  };

  handleClickButton(state) {
    this.setState({
      [state]: true
    });
  }

  submitsearch = () => {
    this.handleClickButton("openBottom");
    const { searchterm } = this.state;
    axios
      .post(configApi.getsearchusers, {
        searchterm,
        usertype: "any"
      })
      .then(res => {
        const userRes = res.data;
        if (!userRes.success)
          return this.setState({ searchResultMessage: userRes.message });
        this.setState(prevState => ({
          listusers: [...prevState.listusers, ...userRes.response]
        }));
      })
      .catch(e => {
        //sentry
        console.log("😱", e);
      });
  };

  listUsers = items => {
    const useritems = items.map((item, i) => {
      return (
        <Link
          key={i}
          className="d-block font-bold secondary-color m-a-md"
          to={{
            pathname: `/user/${item.username}`,
            data: { utype: item.usertype }
          }}
        >
          {item.name}
        </Link>
      );
    });

    return useritems;
  };

  render() {
    const { classes } = this.props;
    const { listusers, searchResultMessage } = this.state;
    return (
      <div>
        <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: classes.margin + " " + classes.search
            }}
            inputProps={{
              placeholder: "Search",
              name: "searchterm",
              inputProps: {
                "aria-label": "Search",
                onChange: this.handleChange
              }
            }}
          />
          <Button
            onClick={this.submitsearch}
            color="white"
            aria-label="edit"
            justIcon
            round
            buttonRef={node => {
              this.anchorElBottom = node;
            }}
          >
            <Search />
          </Button>
          <Popover
            classes={{
              paper: classes.popover
            }}
            open={this.state.openBottom}
            anchorEl={this.anchorElBottom}
            anchorReference={"anchorEl"}
            onClose={() => this.handleClosePopover("openBottom")}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            {listusers.length <= 0 ? (
              <div className="min-height-xs text-center">
                <div className="overlay transparent">
                  <div className="lds-ripple pos-ab top-0">
                    <div />
                    <div />
                  </div>
                </div>
                <span>{searchResultMessage}</span>
              </div>
            ) : (
              this.listUsers(listusers)
            )}
          </Popover>
        </div>
        {/* <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Dashboard"
          className={classes.buttonLink}
        >
          <Dashboard className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Dashboard</p>
          </Hidden>
        </Button> */}
        {/* <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className={classes.buttonLink}
          >
            <Notifications className={classes.icons} />
            <span className={classes.notifications}>5</span>
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Notification
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        Mike John responded to your email
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        You have 5 new tasks
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        You're now friend with Andrew
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        Another Notification
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        Another One
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div> */}
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Person"
          className={classes.buttonLink}
          onClick={() => window.location.replace(`/user`)}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
