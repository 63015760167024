import React from "react";
import { Link } from "react-router-dom";
import GridItem from "components/Grid/GridItem.jsx";

const userItem = ({ classData, userData }) => {
  const itemList = userData.map((item, i) => {
    return (
      <GridItem xs={6} sm={3} md={3} key={`${item.username}-${i}`}>
        <div className="m-b-md">
          <Link
            to={{
              pathname: `/user/${item.username}`,
              data: { utype: item.usertype }
            }}
          >
            <img
              src={item.picture}
              alt={item.username}
              width="200"
              height="200"
              className={`border-round transparent-icon avartaImg pos-rel`}
            />
            <div className="card-list-info">
              <h4
                className={`${
                  classData.cardTitle
                } font-xs font-bold text-ellipsis min-width-xs text-center`}
              >
                {item.name}
              </h4>
            </div>
          </Link>
        </div>
      </GridItem>
    );
  });
  return itemList;
};

export default userItem;
