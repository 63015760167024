/* eslint-disable */
import React from "react";
// creates a beautiful scrollbar

const Logout = () =>{
  if(localStorage.getItem("token") !== null) 
    localStorage.clear()
    window.history.go("/login")
  return null;
}

export default Logout;


