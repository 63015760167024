import React from "react";
import axios from "axios";
import configApi from "../../services/config.json";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Icon from "@material-ui/core/Icon";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import navlinks from "routes.js";
import logo from "assets/img/Fanbies_logo.png";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import image from "assets/img/sidebar-1.jpg";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ListOfAllAffiliate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image,
      color: "blue",
      mobileOpen: false,
      submitDisabled: false,
      affProgramStatusOption: ["listed", "unlisted"],
      listArray: [],
      modal: false,
      modalItem: "",
      modaltype: "",
      owner_username: "",
      conversion: 0,
      status: "",
      ownerLink: ""
    };
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
    this.setState({ modaltype: "", modalItem: "" });
  }

  loadaffiliates() {
    axios
      .post(configApi.getallaffiliateuser, {
        jtoken: localStorage.getItem("token")
      })
      .then(res => {
        const linksData = res.data;
        this.setState({
          listArray: linksData.response
        });
      })
      .catch(() => {
        //sentry
      });
  }

  componentDidMount() {
    this.loadaffiliates();
  }

  // handle updating conversion i.e sales
  handleUpdateConversion = () => {
    const { modalItem, conversion } = this.state;
    if (localStorage.getItem("token") === null || modalItem.affId === null)
      return;

    this.setState({ submitDisabled: true });
    axios
      .post(configApi.updateuserconversion, {
        jtoken: localStorage.getItem("token"),
        affId: modalItem.affId,
        affiliateProfileId: modalItem.affiliateProfileId,
        userid: modalItem.userid,
        username: modalItem.username,
        useremail: modalItem.useremail,
        affTitle: modalItem.title,
        salePrice: modalItem.salePrice,
        conversion
      })
      .then(res => {
        const resData = res.data;
        if (!resData.success) return this.setState({ error: resData.message });

        this.handleClose("modal");
        setTimeout(() => {
          this.props.history.replace("/dashboard");
        }, 900);
        this.setState({ submitDisabled: false });
      })
      .catch(() => {});
  };

  // handle updating user affiliate status
  handleUpdateStatus = () => {
    const { modalItem, status } = this.state;
    if (localStorage.getItem("token") === null || modalItem.affId === null)
      return;

    this.setState({ submitDisabled: true });
    axios
      .post(configApi.updateuseraffstatus, {
        jtoken: localStorage.getItem("token"),
        affId: modalItem.affId,
        affiliateProfileId: modalItem.affiliateProfileId,
        userid: modalItem.userid,
        username: modalItem.username,
        useremail: modalItem.useremail,
        affTitle: modalItem.title,
        affStatus: status
      })
      .then(res => {
        const resData = res.data;
        if (!resData.success) return this.setState({ error: resData.message });

        this.handleClose("modal");
        setTimeout(() => {
          this.props.history.replace("/dashboard");
        }, 900);
        this.setState({ submitDisabled: false });
      })
      .catch(() => {});
  };

  // handle updating user affiliate status
  handleUpdateOwnerLink = () => {
    const { modalItem, ownerLink } = this.state;
    if (localStorage.getItem("token") === null || modalItem.affId === null)
      return;

    this.setState({ submitDisabled: true });
    axios
      .post(configApi.updateuserafflink, {
        jtoken: localStorage.getItem("token"),
        affId: modalItem.affId,
        affiliateProfileId: modalItem.affiliateProfileId,
        userid: modalItem.userid,
        userAffLink: ownerLink
      })
      .then(res => {
        const resData = res.data;
        if (!resData.success) return this.setState({ error: resData.message });

        this.handleClose("modal");
        setTimeout(() => {
          this.props.history.replace("/dashboard");
        }, 900);
        this.setState({ submitDisabled: false });
      })
      .catch(() => {});
  };

  handleChange = ({ currentTarget: input }) => {
    const formState = { ...this.state };
    formState[input.name] = input.value;
    this.setState(formState);
  };

  handelSelectedStatus = val => {
    this.setState({ status: val.target.textContent });
  };

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  openItemModal = (item, modaltype) => {
    if (item == null) return;
    this.setState({
      modal: true,
      modalItem: item,
      modaltype,
      ownerLink: item.ownerLink,
      owner_username: item.username,
      conversion: item.conversion,
      status: item.status
    });
  };

  renderLinks = items => {
    const itemlist = items.map((item, i) => {
      return (
        <Card key={i}>
          <CardBody className="p-a-none">
            <GridContainer justify="center">
              <GridItem className="m-b-lg">
                <img
                  src={item.image}
                  width="150"
                  height="150"
                  alt="user preview"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer className="m-a-lg">
                  <GridItem xs={4} sm={4} md={4} className="m-a-lg">
                    <b>Affiliate Title: </b>
                    {item.title}
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <b>Affiliate Link: </b>
                    {item.link}
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <b>Affiliate Clicks: </b>
                    {item.click}
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <b>Affiliate Status: </b>
                    {item.status}
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <b>Affiliate Conversion: </b>
                    <u>{item.conversion}</u>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <b>Affiliate ($) Sale Price: </b>
                    <u>{item.salePrice}</u>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <b>Affiliate ($) Earned: </b>
                    <u>{item.earnings}</u>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <b>Affiliate Unique Owner Link: </b>
                    {item.ownerLink}
                  </GridItem>
                </GridContainer>
                <GridContainer className="m-a-lg">
                  <GridItem xs={4} sm={4} md={4}>
                    <br />
                    <strong>Owner Details Username</strong>
                    <br />
                    <span>Username: {item.username}</span>
                    <br />
                    <span>Email: {item.useremail}</span>
                    <br />
                    <Link to={{ pathname: `/user/${item.username}` }}>
                      <img
                        src={item.picture}
                        alt={item.username}
                        width="100"
                        height="100"
                        className="border-round pos-rel transparent-icon"
                      />
                    </Link>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem>
                <Button
                  color="success"
                  className="m-a-lg"
                  onClick={() => this.openItemModal(item, "conversion")}
                >
                  <Icon>fullscreen</Icon> Update conversion
                </Button>
                <Button
                  className="m-a-lg"
                  color="rose"
                  onClick={() => this.openItemModal(item, "status")}
                >
                  <Icon>fullscreen</Icon> Update Status
                </Button>
                <Button
                  className="m-a-lg"
                  color="info"
                  onClick={() => this.openItemModal(item, "link")}
                >
                  <Icon>fullscreen</Icon> Add Unique Owner Link
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      );
    });
    return itemlist;
  };

  itemModalView = () => {
    const { classes } = this.props;
    const {
      modal,
      modaltype,
      submitDisabled,
      conversion,
      status,
      ownerLink,
      affProgramStatusOption,
      owner_username
    } = this.state;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose("modal")}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent
          id="modal-slide-description"
          className={`${classes.modalBody} p-a-lg`}
        >
          <GridContainer>
            {modaltype === "conversion" && (
              <>
                <GridItem xs={12} sm={12} md={12}>
                  <p>Update Conversion for user {owner_username}</p>
                  <p>
                    Current conversion is at: {conversion}, Very important to
                    increase accordingly
                  </p>
                  <CustomInput
                    labelText="Conversion"
                    id="conversion"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      name: "conversion",
                      value: `${conversion}`,
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    color="primary"
                    className="floatRight"
                    disabled={submitDisabled}
                    onClick={this.handleUpdateConversion}
                    round
                  >
                    Submit Update Conversion
                  </Button>
                </GridItem>
              </>
            )}
            {modaltype === "status" && (
              <>
                <GridItem xs={4} sm={4} md={12}>
                  <p>Update Status of Affiliate for user {owner_username}</p>
                  <div className="d-inline-block m-v-md">
                    <CustomDropdown
                      buttonText="Status?"
                      buttonProps={{
                        color: "primary",
                        round: true
                      }}
                      dropdownList={affProgramStatusOption}
                      onClick={this.handelSelectedStatus}
                    />
                  </div>
                  <span className="m-h-md d-inline-block">{status}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    color="primary"
                    className="floatRight"
                    disabled={submitDisabled}
                    onClick={this.handleUpdateStatus}
                    round
                  >
                    Submit Update Status
                  </Button>
                </GridItem>
              </>
            )}
            {modaltype === "link" && (
              <>
                <GridItem xs={4} sm={4} md={12}>
                  <p>Update / add unique link for user {owner_username}</p>
                  <CustomInput
                    labelText="OwnerLink"
                    id="ownerLink"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "ownerLink",
                      value: `${ownerLink}`,
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    color="primary"
                    className="floatRight"
                    disabled={submitDisabled}
                    onClick={this.handleUpdateOwnerLink}
                    round
                  >
                    Update Live Owner Link
                  </Button>
                </GridItem>
              </>
            )}
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    const { classes, ...rest } = this.props;
    const { mobileOpen, color, listArray } = this.state;
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={navlinks}
          logoText={""}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        />
        <div>
          <Card>
            <CardHeader color="primary">
              <h4 className={`${classes.cardTitleWhite} font-size-lg`}>
                Affiliate Programs
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {listArray.length ? (
                    this.renderLinks(listArray)
                  ) : (
                    <p>No User Has Joined Fanbies Affiliate Program(s)</p>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {this.itemModalView()}
        </div>
      </div>
    );
  }
}
export default withStyles(dashboardStyle)(ListOfAllAffiliate);
