import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import configApi from "../services/config.json";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import CardAvatar from "components/Card/CardAvatar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import logo from "assets/img/Fanbies_logo.png";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import image from "assets/img/sidebar-1.jpg";
import Danger from "components/Typography/Danger.jsx";
import Info from "components/Typography/Info.jsx";

import routes from "routes.js";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}
class CompletedRequestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      openBottom: false,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      error: "",
      submitDisabled: false,
      isLoaded: false,
      completedArr: [],
      successMessage: "",
      infoMessage: "",
      utype: 1,
      modal: false
    };
  }

  validatToken = () => {
    axios
      .post(configApi.validatetoken, {
        jwtoken: localStorage.getItem("token")
      })
      .then(res => {
        const tokenRes = res.data;
        if (!tokenRes.success) return <Redirect to="/login" />;

        this.setState({ utype: tokenRes.response.utype });
        this.getCompletedRequest();
      })
      .catch(e => {
        console.log("🤪", e);
      });
  };

  componentDidMount() {
    this.validatToken();
  }

  handleRequestInvoice = id => {
    this.setState({ submitDisabled: true });
    axios
      .post(configApi.invoicerequest, {
        shoutoutid: id
      })
      .then(res => {
        const invoiceRes = res.data;
        if (!invoiceRes.success) this.setState({ error: invoiceRes.message });

        this.setState({
          successMessage: invoiceRes.message,
          submitDisabled: false
        });
        this.openModal("modal");
      })
      .catch(e => {
         console.log("🤪", e);
      });
  };

  getCompletedRequest = () => {
    this.setState({ error: "", infoMessage: "" });
    const jtoken = localStorage.getItem("token");
    axios
      .post(configApi.getcompletedshoutout, {
        username: this.props.match.params.username,
        jtoken
      })
      .then(res => {
        const completedRes = res.data;
        if (!completedRes.success)
          return this.setState({ error: completedRes.message });
        if (completedRes.response.length <= 0)
          return this.setState({
            infoMessage: "You have no request completed at this moment.",
            isLoaded: true
          });
        //console.log(completedRes.response);
        this.setState({ isLoaded: true });
        this.setState(prevState => ({
          completedArr: [...prevState.completedArr, ...completedRes.response]
        }));
      })
      .catch(e => {
        console.log("🤪", e);
      });
  };

  renderDateStamp(stamp) {
    // Convert timestamp to milliseconds
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    var date = new Date(stamp * 1000);

    // Year
    var year = date.getFullYear();

    // Month
    var month = months_arr[date.getMonth()];

    // Day
    var day = date.getDate();

    // Display date time in MM-dd-yyyy h:m:s format
    const convdataTime = `${month}-${day}-${year}`;

    return convdataTime;
  }

  isInvoiceOverDue = stamp => {
    let date = new Date(stamp * 1000);
    let sevendaysStamp = date.setDate(date.getDate() + 7);
    let nowTimeStamp = Date.now();

    return sevendaysStamp > nowTimeStamp;
  };

  renderList = items => {
    const { classes } = this.props;
    const itemlist = items.map((item, i) => {
      return (
        <GridItem xs={12} sm={12} md={6} key={i}>
          <Card>
            {/* <CardAvatar profile>
              <img
                src={item.picture}
                alt={`${item.name} profile pic`}
                className={`img-align-center profileImg`}
              />
            </CardAvatar> */}
            <CardBody>
              <h4 className={classes.description}>
                Request ID:&nbsp;
                <span className="font-bold">#FBN-19-{item.shoutout_id}</span>
              </h4>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Owner Name:&nbsp;
                <span className="font-bold">{item.name}</span>
              </h6>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Requested Date:&nbsp;
                <span className="font-bold">
                  {this.renderDateStamp(item.requested_date)}
                </span>
              </h6>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Completed Date:&nbsp;
                <span className="font-bold">
                  {this.renderDateStamp(item.date)}
                </span>
              </h6>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Request title:&nbsp;
                <span className="font-bold">{item.mention_name}</span>
              </h6>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Display Charges <br />{" "}
                <span className="font-size-sm">
                  (Service Charge Not Deducted)
                </span>
                :&nbsp;
                <span className="font-bold">$ {item.charge}</span>
              </h6>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Paid invoiced?:&nbsp;
                <span className="font-bold">
                  {item.invoiced === 1 ? "Yes" : "No"}
                </span>
              </h6>
              {this.rendeInvoiceButton(
                item.invoiced,
                item.date,
                item.shoutout_id
              )}
            </CardBody>
          </Card>
        </GridItem>
      );
    });
    return itemlist;
  };

  rendeInvoiceButton = (invoiced, stamp, id) => {
    let due = this.isInvoiceOverDue(stamp);
    const { submitDisabled } = this.state;
    if (invoiced === 1) {
      return (
        <Button color="info" round disabled={true}>
          Invoice Paid
        </Button>
      );
    } else {
      if (due) {
        return (
          <React.Fragment>
            <Button color="warning" round disabled={true}>
              Processing...
            </Button>
            <p>Please note 7 days wait time after completion date.</p>
          </React.Fragment>
        );
      } else {
        return (
          <Button
            color="rose"
            round
            disabled={submitDisabled}
            onClick={() => this.handleRequestInvoice(id)}
          >
            Request For Invoice
          </Button>
        );
      }
    }
  };

  openModal(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  render() {
    if (localStorage.getItem("token") === null) return <Redirect to="/login" />;
    const { classes, ...rest } = this.props;
    const {
      isLoaded,
      error,
      completedArr,
      successMessage,
      utype,
      infoMessage
    } = this.state;
    let navlinks;
    if (utype < 2) {
      navlinks = _.filter(routes, ["type", "default"]);
    } else {
      navlinks = routes;
    }
    return (
      <div className={`${classes.wrapper}`}>
        <Sidebar
          routes={navlinks}
          logoText={""}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div className={classes.mainPanel}>
          <header className="customHeader">Completed Request Page</header>
          <Danger>{error}</Danger>
          <Info>
            <h3>{infoMessage}</h3>
          </Info>
          <div className={`m-a-lg`}>
            <GridContainer>
              {isLoaded ? (
                this.renderList(completedArr)
              ) : (
                <GridItem xs={12} sm={12} md={4}>
                  <div className="text-center pos-rel min-height-sm">
                    <div className="overlay transparent">
                      <div className="lds-ripple pos-ab top-0">
                        <div />
                        <div />
                      </div>
                    </div>
                  </div>
                </GridItem>
              )}
            </GridContainer>
          </div>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <Info>{successMessage}</Info>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default withStyles(dashboardStyle)(CompletedRequestPage);
