import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import configApi from "../../services/config.json";
//import classNames from "classnames";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// @material-ui/icons
//core components
import UserItem from "../UserProfile/UserItem";
//import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
class NormalUsers extends React.Component {
  state = {
    settingsChecked: [1, 2],
    talentArry: [],
    submitDisabled: false,
    successMsg: "",
    error: "",
    listusers: [],
    isLoaded: false,
    offset: 0
  };

  loadNormalUserApi = () => {
    axios
      .post(configApi.userlist, {
        jtoken: localStorage.getItem("token"),
        usertype: 0,
        offset: this.state.offset
      })
      .then(res => {
        const resData = res.data;
        if (!resData.success) return this.setState({ error: resData.message });

        this.setState({ listusers: resData.response, isLoaded: true });
      })
      .catch(e => {
        console.log("😱", e);
      });
  };

  loadmore = () => {
    this.setState({ offset: this.state.offset + 1, isLoaded: false });
    axios
      .post(configApi.userlist, {
        offset: this.state.offset + 1,
        jtoken: localStorage.getItem("token"),
        usertype: 0
      })
      .then(res => {
        const resData = res.data;
        if (!resData.success) return this.setState({ error: resData.message });

        this.setState(prevState => ({
          listusers: [...prevState.listusers, ...resData.response]
        }));
        this.setState({ isLoaded: true });
      })
      .catch(e => {
        console.log("😱", e);
      });
  };

  componentDidMount() {
    this.loadNormalUserApi();
  }

  render() {
    const { classes } = this.props;
    const { error, isLoaded, listusers } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Fanbies Users</h4>
            </CardHeader>
            <CardBody>
              <p className="secondary-color">{error}</p>
              {!isLoaded ? (
                <div className="min-height-sm text-center">
                  <div className="overlay transparent">
                    <div className="lds-ripple pos-ab top-0">
                      <div />
                      <div />
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div>
                    <GridContainer>
                      {listusers.length <= 0 ? (
                        <div className="min-height-sm text-center">
                          <div className="overlay transparent">
                            <div className="lds-ripple pos-ab top-0">
                              <div />
                              <div />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <UserItem classData={classes} userData={listusers} />
                      )}
                    </GridContainer>
                  </div>
                </React.Fragment>
              )}
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={this.loadmore} round>
                Load More
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
export default withStyles(styles)(NormalUsers);
