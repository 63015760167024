/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
//Joi
import Joi from "joi-browser";
//axios
import axios from "axios";
import configApi from "../services/config.json";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Header from "components/Header/Header.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Danger from "components/Typography/Danger.jsx";

import loginStyle from "assets/jss/material-dashboard-react/views/loginStyle.jsx";
import image from "assets/img/profile-bg.jpg";
import "../App.css";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class LoginForm extends React.Component {
   anchorElBottom = null;
  constructor(props) {
    super(props);
    this.state = {
      openBottom: false,
      cardAnimaton: "cardHidden",
      confirmationMessage: "",
      submitDisabled: false,
      error: "",
      modal: false
    };
  }

  schema = {
    useremail: Joi.string()
      .required()
      .email()
      .label("email value ")
  };

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClickButton(state) {
    this.setState({
      [state]: true
    });
  }

  handleChange = ({ currentTarget: input }) => {
    const formState = { ...this.state };
    formState[input.name] = input.value;
    this.setState(formState);
  };

  handelSubmit = e => {
    e.preventDefault();
    const { useremail } = this.state;
    const validatorObj = {
      useremail
    };
    const result = Joi.validate(validatorObj, this.schema);
    this.setState({ error: "" });

    if (result.error !== null)
      return this.setState({ error: result.error.details[0].message });

    this.setState({ submitDisabled: true });
    this.resetApi();
  };

  resetApi() {
    const { useremail } = this.state;
    axios
      .post(configApi.forgottentreset, {
        useremail
      })
      .then(res => {
        const resetRes = res.data;
        if (!resetRes.success)
          return this.setState({ error: resetRes.message, submitDisabled: false });

       //success response callback
        this.successResponse(resetRes.message);
      })
      .catch(() => {
        this.setState({ submitDisabled: false });
      });
  }

  successResponse(res) {
    this.setState({ confirmationMessage: res, modal: true });
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
 
  render() {
    const { classes, ...rest } = this.props;
    const { error, submitDisabled, confirmationMessage } = this.state;
    return (
      <div>
        <Header absolute color="transparent" brand="Fanbies" {...rest} />
        <div
            className={classes.pageHeader}
            style={{
              backgroundImage: "url(" + image + ")",
              backgroundSize: "cover",
              backgroundPosition: "top center"
            }}
          >
          <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={4}>
                  <Card className={classes[this.state.cardAnimaton]}>
                    <form className={classes.form} noValidate="noValidate">
                      <CardHeader color="danger" className={classes.cardHeader}>
                        <h4>Forgotten Password</h4>
                      </CardHeader>
                      <CardBody>
                        <span className="text-center">
                          <Danger>{error}</Danger>
                        </span>
                        <CustomInput
                          labelText="Email..."
                          id="first"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            onChange: this.handleChange,
                            name: "useremail",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  email_outline
                                </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                      </CardBody>
                      <CardFooter className={classes.cardFooter}>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={this.handelSubmit}
                          disabled={submitDisabled}
                        >
                          Submit
                        </Button>
                        <br />
                      </CardFooter>
                    </form>
                  </Card>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal
                    }}
                    open={this.state.modal}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                  >
                    <DialogContent
                      id="modal-slide-description"
                      className={classes.modalBody}
                    >
                      {confirmationMessage}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.props.history.replace("/dashboard")}
                        color="primary"
                      >
                        Continue
                      </Button>
                    </DialogActions>
                  </Dialog>
                </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginStyle)(LoginForm);
