import React from "react";
import axios from "axios";
import configApi from "../services/config.json";
import Joi from "joi-browser";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Danger from "components/Typography/Danger.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import navlinks from "routes.js";
import logo from "assets/img/Fanbies_logo.png";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import image from "assets/img/sidebar-1.jpg";

class AddProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      openBottom: false,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      error: "",
      password: "",
      repassword: "",
      username: "",
      name: "",
      useremail: "",
      submitDisabled: false,
      isLoading: false,
      usertypeArr: ["Normal", "Fanbies"],
      usertype: 0,
      successMessage: ""
    };
  }

  addProfile = () => {
    const {
      useremail,
      name,
      username,
      password,
      repassword,
      usertype
    } = this.state;
    this.setState({ successMessage: "" });
    axios
      .post(configApi.adduserprofile, {
        useremail,
        name,
        username,
        password,
        repassword,
        usertype,
        jtoken: localStorage.getItem("token")
      })
      .then(res => {
        const userRes = res.data;
        this.setState({ submitDisabled: false });
        if (!userRes.success) return this.setState({ error: userRes.message });

        //
        this.setState({ successMessage: userRes.message });
        setTimeout(() => {
          this.props.history.replace("/dashboard");
        }, 900);
      })
      .catch(e => {
        //sentry
        console.log("😱", e);
        this.setState({ submitDisabled: false });
      });
  };

  handleChange = ({ currentTarget: input }) => {
    const formState = { ...this.state };
    formState[input.name] = input.value;
    this.setState(formState);
  };

  validateForm = e => {
    e.preventDefault();
    const { useremail, name, username, password, repassword } = this.state;
    const validatorObj = {
      useremail,
      name,
      username,
      password,
      repassword
    };
    const result = Joi.validate(validatorObj, this.schema);
    this.setState({ error: "" });
    if (result.error !== null)
      return this.setState({ error: result.error.details[0].message });

    this.setState({ submitDisabled: true });
    this.addProfile();
  };

  // schema for form validation
  schema = {
    useremail: Joi.string()
      .required()
      .email({ minDomainAtoms: 2 })
      .label("Email value "),
    username: Joi.string()
      .required()
      .regex(/^[a-zA-Z0-9\-_]{0,20}$/)
      .label(
        "Error - No Spaces; Usernames can only use letters, numbers, underscores and periods."
      ),
    name: Joi.string()
      .min(3)
      .max(99)
      .required()
      .label("Name value "),
    password: Joi.string()
      .required()
      .regex(/^[a-zA-Z0-9]{6,30}$/)
      .label("Password should be length 6 - 30 with multiple char. "),
    repassword: Joi.string()
      .valid(Joi.ref("password"))
      .label("Confirm Password is not equal to Password ")
  };

  handelSelectedStatus = val => {
    let valueSet;
    if (val.target.textContent === "Normal") {
      valueSet = 0;
    } else if (val.target.textContent === "Fanbies") {
      valueSet = 1;
    } else {
      valueSet = 0;
    }

    this.setState({ usertype: valueSet });
  };

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  render() {
    const { classes, ...rest } = this.props;
    const {
      submitDisabled,
      usertypeArr,
      usertype,
      error,
      successMessage
    } = this.state;
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={navlinks}
          logoText={""}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div className={classes.mainPanel}>
          <header className="customHeader">User Page</header>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={`${classes.cardTitleWhite} font-size-lg`}>
                    Add Profile
                  </h4>
                </CardHeader>
                <CardBody>
                  <Danger>{error}</Danger>
                  <p>{successMessage}</p>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Username"
                        id="username-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "username",
                          onChange: this.handleChange
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Email"
                        id="email-address"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          name: "useremail",
                          onChange: this.handleChange
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Name"
                        id="common-name"
                        className="font-bold"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "name",
                          onChange: this.handleChange
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7}>
                      <div className="d-inline-block m-v-md">
                        <CustomDropdown
                          buttonText="User Type?"
                          buttonProps={{
                            color: "primary",
                            round: true
                          }}
                          dropdownList={usertypeArr}
                          onClick={this.handelSelectedStatus}
                        />
                      </div>
                      <span className="m-h-md d-inline-block">
                        {usertype === 0 ? "Normal" : ""}
                        {usertype === 1 ? "Fanbies" : ""}
                      </span>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Password"
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          name: "password",
                          onChange: this.handleChange
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Confirm Password"
                        id="repassword"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          name: "repassword",
                          onChange: this.handleChange
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button
                    color="primary"
                    onClick={this.validateForm}
                    disabled={submitDisabled}
                  >
                    Add Profile
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(dashboardStyle)(AddProfile);
