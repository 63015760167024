import React from "react";
import axios from "axios";
import configApi from "../../services/config.json";
import Joi from "joi-browser";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Icon from "@material-ui/core/Icon";
import Danger from "components/Typography/Danger.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import navlinks from "routes.js";
import logo from "assets/img/Fanbies_logo.png";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import image from "assets/img/sidebar-1.jpg";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import InputLabel from "@material-ui/core/InputLabel";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ListAffiliate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image,
      color: "blue",
      mobileOpen: false,
      error: "",
      submitDisabled: false,
      affStatusOption: ["active", "disabled"],
      affStatus: "",
      successMessage: "",
      imagePreviewUrl: "",
      feedImgFile: null,
      title: "",
      program: "",
      commission: "",
      salePrice: 0,
      link: "",
      description: "",
      listArray: [],
      modal: false,
      modalItem: ""
    };
    this.fileInput = React.createRef();
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadaffiliates() {
    axios
      .post(configApi.getallaffiliateitem, {
        jtoken: localStorage.getItem("token")
      })
      .then(res => {
        const linksData = res.data;
        this.setState({
          listArray: linksData.response
        });
      })
      .catch(() => {
        //sentry
      });
  }

  componentDidMount() {
    this.loadaffiliates();
  }

  handleJoinAffiliate = () => {
    const { modalItem } = this.state;
    if (localStorage.getItem("token") === null || modalItem.id === null) return;

    this.setState({ submitDisabled: true });
    axios
      .post(configApi.joinaffiliate, {
        jtoken: localStorage.getItem("token"),
        itemid: modalItem.id
      })
      .then(res => {
        const resData = res.data;
        if (!resData.success) return this.setState({ error: resData.message });

        this.handleClose("modal");
        this.setState({ submitDisabled: false });
      })
      .catch(() => {});
  };

  handleUpdate = () => {
    const {
      affStatus,
      title,
      commission,
      salePrice,
      link,
      description,
      program,
      feedImgFile,
      modalItem
    } = this.state;
    if (localStorage.getItem("token") === null || modalItem.id === null) return;

    let data = new FormData();
    data.append("file", feedImgFile);
    data.set("itemid", modalItem.id);
    data.set("currentImg", modalItem.image);
    data.set("title", title);
    data.set("jtoken", localStorage.getItem("token"));
    data.set("affStatus", affStatus);
    data.set("commission", commission);
    data.set("salePrice", salePrice);
    data.set("link", link);
    data.set("description", description);
    data.set("program", program);

    this.setState({ successMessage: "" });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data,
      url: configApi.updateaffiliateitem
    };

    axios(options)
      .then(res => {
        const uRes = res.data;
        this.setState({ submitDisabled: false });
        if (!uRes.success) return this.setState({ error: uRes.message });

        this.setState({ successMessage: uRes.message });
        setTimeout(() => {
          this.props.history.replace("/dashboard");
        }, 900);
      })
      .catch(() => {
        this.setState({ submitDisabled: false });
      });
  };

  handleChange = ({ currentTarget: input }) => {
    const formState = { ...this.state };
    formState[input.name] = input.value;
    this.setState(formState);
  };

  validateForm = e => {
    e.preventDefault();
    const { title, commission, salePrice, link, description } = this.state;
    const validatorObj = {
      title,
      commission,
      salePrice,
      link,
      description
    };
    const result = Joi.validate(validatorObj, this.schema);
    this.setState({ error: "" });
    if (result.error !== null)
      return this.setState({ error: result.error.details[0].message });

    this.setState({ submitDisabled: true });
    this.handleUpdate();
  };

  // schema for form validation
  schema = {
    commission: Joi.string()
      .required()
      .label("Error: What is the commission "),
    salePrice: Joi.number()
      .required()
      .label("Error: What is the sale Price of item "),
    title: Joi.string()
      .required()
      .label(
        "Error: No Spaces; titles can only use letters, numbers, underscores and periods."
      ),
    link: Joi.string()
      .required()
      .label("Error: Link is required"),
    description: Joi.string()
      .required()
      .label("Description is required ")
  };

  handelSelectedStatus = val => {
    this.setState({ affStatus: val.target.textContent });
  };

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  refreshStreams = () => {
    this.setState({
      error: "",
      feedImgFile: null,
      imagePreviewUrl: "",
      recordedStream: null
    });
  };

  handleImageFeed = () => {
    this.refreshStreams();
    let reader = new FileReader();
    let file = this.fileInput.current.files[0];
    let imgSize = this.fileInput.current.files[0].size / 1024 / 1024;
    if (imgSize > 4) {
      this.setState({
        error: "Image size is large, we only accept 4MB currently"
      });
      return;
    }

    reader.onloadend = () => {
      this.setState({
        error: "",
        feedImgFile: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  openItemModal = item => {
    if (item == null) return;
    this.setState({
      modal: true,
      modalItem: item,
      title: item.title,
      program: item.program,
      commission: item.commission,
      salePrice: item.salePrice,
      link: item.link,
      description: item.description,
      affStatus: item.status
    });
  };

  renderLinks = items => {
    const itemlist = items.map((item, i) => {
      return (
        <Card key={i}>
          <CardBody className="p-a-none">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <img
                  src={item.image}
                  alt="user preview"
                  className={`feedImgPreview m-v-md`}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <b>Title: </b>
                {item.title}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <b>Program: </b>
                {item.program}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <b>Commission: </b>
                {item.commission}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <b>Sale Price: </b>
                {item.salePrice}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <b>Link: </b>
                {item.link}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <b>Description: </b>
                {item.description}
              </GridItem>
              <GridItem>
                <b>Status: </b>
                {item.status}
              </GridItem>
              <GridItem>
                <Button
                  simple
                  color="primary"
                  onClick={() => this.openItemModal(item)}
                >
                  <Icon>fullscreen</Icon> Open Item
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      );
    });
    return itemlist;
  };

  itemModalView = () => {
    const { classes } = this.props;
    const {
      error,
      modal,
      modalItem,
      affStatusOption,
      title,
      program,
      commission,
      salePrice,
      link,
      description,
      affStatus,
      submitDisabled
    } = this.state;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose("modal")}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent
          id="modal-slide-description"
          className={`${classes.modalBody} p-a-lg`}
        >
          <Danger>{error}</Danger>
          <GridContainer>
            <GridItem>
              <div className="content-center">
                <input
                  accept="image/jpeg, image/png"
                  className={`${classes.input} fileInput`}
                  id="imgfile"
                  type="file"
                  onChange={this.handleImageFeed}
                  ref={this.fileInput}
                />
                <label
                  htmlFor="imgfile"
                  className="d-block text-center clickable-icon m-h-lg"
                >
                  Click icon to upload Image &nbsp;
                  <AddPhotoAlternate
                    style={{
                      color: "#000",
                      fontSize: "4em",
                      display: "block",
                      margin: "0 auto"
                    }}
                  />
                </label>
              </div>
              {this.state.imagePreviewUrl ? (
                <img
                  src={this.state.imagePreviewUrl}
                  alt="new affiliate preview"
                  className="feedImgPreview m-v-md"
                />
              ) : (
                <img
                  src={modalItem.image}
                  alt="affiliate preview"
                  className="feedImgPreview m-v-md"
                />
              )}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Title"
                id="title"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  name: "title",
                  value: `${title}`,
                  onChange: this.handleChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Program"
                id="affiliate-program"
                className="font-bold"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  name: "program",
                  value: `${program}`,
                  onChange: this.handleChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={12}>
              <CustomInput
                labelText="Commission"
                id="affiliate-commission"
                className="font-bold"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  name: "commission",
                  value: `${commission}`,
                  onChange: this.handleChange
                }}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={12}>
              <CustomInput
                labelText="salePrice"
                id="affiliate-salePrice"
                className="font-bold"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  name: "salePrice",
                  value: `${salePrice}`,
                  onChange: this.handleChange
                }}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={12}>
              <div className="d-inline-block m-v-md">
                <CustomDropdown
                  buttonText="Status?"
                  buttonProps={{
                    color: "primary",
                    round: true
                  }}
                  dropdownList={affStatusOption}
                  onClick={this.handelSelectedStatus}
                />
              </div>
              <span className="m-h-md d-inline-block">{affStatus}</span>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Link"
                id="affiliate-link"
                className="font-bold"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  name: "link",
                  value: `${link}`,
                  onChange: this.handleChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <InputLabel style={{ color: "#AAAAAA" }}>About</InputLabel>
              <CustomInput
                labelText="Description"
                id="description"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  name: "description",
                  value: `${description}`,
                  onChange: this.handleChange,
                  rows: 5
                }}
              />
              <Button
                color="warning"
                className="floatLeft"
                disabled={submitDisabled}
                onClick={this.handleJoinAffiliate}
              >
                Join Program
              </Button>
              <Button
                color="primary"
                className="floatRight"
                disabled={submitDisabled}
                onClick={this.validateForm}
                round
              >
                Submit Update
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    const { classes, ...rest } = this.props;
    const { mobileOpen, color, listArray } = this.state;
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={navlinks}
          logoText={""}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        />
        <div>
          <Card>
            <CardHeader color="primary">
              <h4 className={`${classes.cardTitleWhite} font-size-lg`}>
                Affiliate Programs
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {listArray.length ? (
                    this.renderLinks(listArray)
                  ) : (
                    <p>No Affiliate Program(s)</p>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {this.itemModalView()}
        </div>
      </div>
    );
  }
}
export default withStyles(dashboardStyle)(ListAffiliate);
