import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import configApi from "../services/config.json";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import logo from "assets/img/Fanbies_logo.png";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import image from "assets/img/sidebar-1.jpg";
import Danger from "components/Typography/Danger.jsx";
import Popover from "@material-ui/core/Popover";
import Close from "@material-ui/icons/Close";

import routes from "routes.js";
class UserCompletedRequestPage extends React.Component {
  anchorElBottom = null;
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      openBottom: false,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      error: "",
      submitDisabled: false,
      isLoaded: false,
      requestArr: [],
      successMessage: "",
      utype: 1,
      modal: false,
      userDrop: false,
      disableUploadBtn: false,
      recordedIsLoaded: false,
      recordedStream: "",
      dropMessage: "",
      dropLoader: false
    };
    this.fileInput = React.createRef();
    this.uploadVideo = this.uploadVideo.bind(this);
  }

  validatToken = () => {
    axios
      .post(configApi.validatetoken, {
        jwtoken: localStorage.getItem("token")
      })
      .then(res => {
        const tokenRes = res.data;
        if (!tokenRes.success) return <Redirect to="/login" />;

        this.setState({ utype: tokenRes.response.utype });
        if (this.state.utype < 2) return <Redirect to="/dashboard" />;
        this.getPendingRequest();
      })
      .catch(e => {
        console.log("🤪", e);
      });
  };

  componentDidMount() {
    this.validatToken();
  }

  handleUploadClose = state => {
    let previewVideo = document.querySelector("video#preview");
    if (previewVideo !== null) {
      previewVideo.srcObject = null;
      previewVideo.src = null;
    }
    this.setState({
      [state]: false,
      recordedIsLoaded: false
    });
  };

  getPendingRequest = () => {
    this.setState({ error: "" });
    const jtoken = localStorage.getItem("token");
    axios
      .post(configApi.getRequestByStatus, {
        username: this.props.match.params.username,
        status: "created",
        jtoken
      })
      .then(res => {
        const dataRes = res.data;
        if (!dataRes.success) return this.setState({ error: dataRes.message });
        if (dataRes.response.length <= 0)
          return this.setState({
            error: "User has no request pending at this moment."
          });
        this.setState({ isLoaded: true });
        this.setState(prevState => ({
          requestArr: [...prevState.requestArr, ...dataRes.response]
        }));
      })
      .catch(e => {
        console.log("🤪", e);
      });
  };

  renderDateStamp(stamp) {
    // Convert timestamp to milliseconds
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    var date = new Date(stamp * 1000);

    // Year
    var year = date.getFullYear();

    // Month
    var month = months_arr[date.getMonth()];

    // Day
    var day = date.getDate();

    // Display date time in MM-dd-yyyy h:m:s format
    const convdataTime = `${month}-${day}-${year}`;

    return convdataTime;
  }

  getDetails(id) {
    axios
      .post(configApi.getshoutoutbyid, { id })
      .then(res => {
        const requestDetails = res.data;
        if (!requestDetails.success)
          return this.setState({ error: requestDetails.message });

        this.setState({
          uName: requestDetails.response[0].name,
          uPic: requestDetails.response[0].picture,
          uEmail: requestDetails.response[0].email,
          title: requestDetails.response[0].mention_name,
          description: requestDetails.response[0].message_shoutout,
          video_privacy: requestDetails.response[0].privacy,
          owner_id: requestDetails.response[0].requestid,
          response_id: requestDetails.response[0].responseid,
          request_id: requestDetails.response[0].id,
          video_status: requestDetails.response[0].status,
          video_date: requestDetails.response[0].date,
          owners_uname: requestDetails.response[0].owners_name,
          userDrop: true
        });
      })
      .catch(err => {
        //sentry
        console.warn(err);
        //this.setState({ submitDisabled: false });
      });
  }

  handelFileUpload = () => {
    let _this = this;
    this.setState({ dropMessage: "" });
    let previewVideo = document.querySelector("video#preview");
    previewVideo.preload = "metadata";

    previewVideo.onloadedmetadata = () => {
      window.URL.revokeObjectURL(previewVideo.src);
      if (previewVideo.duration > 60) {
        previewVideo.srcObject = null;
        previewVideo.src = null;
        return _this.setState({
          dropMessage:
            "Invalid Video! Video duration should less than 60 second / 1 mintue",
          recordedIsLoaded: false
        });
      }
    };
    previewVideo.srcObject = null;
    previewVideo.src = null;
    previewVideo.src = window.URL.createObjectURL(
      this.fileInput.current.files[0]
    );
    this.setState({
      recordedStream: this.fileInput.current.files[0],
      recordedIsLoaded: true
    });
    previewVideo.controls = true;
    previewVideo.play();
  };

  uploadVideo = () => {
    let data = new FormData();
    const {
      recordedStream,
      uName,
      uEmail,
      video_privacy,
      owner_id,
      response_id,
      owners_uname,
      request_id,
      video_date
    } = this.state;

    data.append("file", recordedStream);
    data.set("ownername", uName);
    data.set("owner_uname", owners_uname);
    data.set("video_privacy", video_privacy);
    data.set("video_owner_id", owner_id);
    data.set("video_recorder_id", response_id);
    data.set("request_id", request_id);
    //emails
    data.set("video_req_date", video_date);
    data.set("video_owner_email", uEmail);
    data.set("video_recorder_email", this.props.location.data.celebEmail);
    data.set("recorder_name", this.props.location.data.celebName);

    let previewVideo = document.querySelector("video#preview");
    previewVideo.srcObject = null;
    previewVideo.src = null;
    //User should be able to submit one recording per session.
    this.setState({
      disableUploadBtn: true,
      dropLoader: true
    });
    setTimeout(() => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data,
        url: configApi.uploadrecvideo
      };
      axios(options).then(res => {
        const resp = res.data;
        if (!resp.success)
          return this.setState({
            dropMessage: resp.message
          });

        this.setState({
          dropMessage: resp.message,
          dropLoader: false
        });
      });
    }, 1000);
  };

  renderList = items => {
    const { classes } = this.props;
    const itemlist = items.map((item, i) => {
      return (
        <GridItem xs={12} sm={12} md={6} key={i}>
          <Card>
            <CardAvatar profile>
              <img
                src={item.picture}
                alt={`${item.name} profile pic`}
                className={`img-align-center profileImg`}
              />
            </CardAvatar>
            <CardBody>
              <h4 className={classes.description}>
                Request ID:&nbsp;
                <span className="font-bold">#FBN-19-{item.id}</span>
              </h4>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Owner Name:&nbsp;
                <span className="font-bold">{item.name}</span>
              </h6>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Charge:&nbsp;
                <span className="font-bold">{`$ ${item.charge}`}</span>
              </h6>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Requested Date:&nbsp;
                <span className="font-bold">
                  {this.renderDateStamp(item.date)}
                </span>
              </h6>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Request title:
              </h6>
              <p className="font-bold">{item.mention_name}</p>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Request Full Details:&nbsp;
              </h6>
              <p className="font-bold">{item.message_shoutout}</p>
              <h6 className={`${classes.cardCategory} m-v-md text-capital`}>
                Privacy:&nbsp;
                <span className="font-bold">
                  {item.privacy ? "Private Video" : "Public"}
                </span>
              </h6>
              <Button
                color="rose"
                simple
                onClick={() => this.getDetails(item.id)}
              >
                Upload User Shoutout Message
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      );
    });
    return itemlist;
  };

  render() {
    if (localStorage.getItem("token") === null) return <Redirect to="/login" />;
    const { classes, ...rest } = this.props;
    const {
      isLoaded,
      error,
      requestArr,
      utype,
      dropMessage,
      recordedIsLoaded,
      disableUploadBtn,
      dropLoader
    } = this.state;
    let navlinks;
    if (utype < 2) {
      navlinks = _.filter(routes, ["type", "default"]);
    } else {
      navlinks = routes;
    }
    return (
      <div className={`${classes.wrapper}`}>
        <Sidebar
          routes={navlinks}
          logoText={""}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div className={classes.mainPanel}>
          <header className="customHeader">Pending Request Page</header>
          <Danger>{error}</Danger>
          <div className={`m-a-lg`}>
            <GridContainer>
              {isLoaded ? (
                this.renderList(requestArr)
              ) : (
                <GridItem xs={12} sm={12} md={4}>
                  <div className="text-center pos-rel min-height-sm">
                    <div className="overlay transparent">
                      <div className="lds-ripple pos-ab top-0">
                        <div />
                        <div />
                      </div>
                    </div>
                  </div>
                </GridItem>
              )}
            </GridContainer>
            <Popover
              classes={{
                paper: classes.popover
              }}
              open={this.state.userDrop}
              anchorEl={this.anchorElBottom}
              anchorReference={"anchorEl"}
              onClose={() => this.handleUploadClose("userDrop")}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center"
              }}
            >
              <Button
                onClick={() => this.handleUploadClose("userDrop")}
                justIcon
                round
                className="floatRight"
                color="warning"
              >
                <Close style={{ color: "#FFFFFF" }} />
              </Button>
              <h3>Upload user shoutout message from Admin dashboard</h3>
              <p>Select / Upload file:</p>
              <GridContainer>
                {dropLoader === true ? (
                  <GridItem xs={12} sm={12} md={4}>
                    <div className="text-center pos-rel min-height-sm">
                      <div className="overlay transparent">
                        <div className="lds-ripple pos-ab top-0">
                          <div />
                          <div />
                        </div>
                      </div>
                    </div>
                  </GridItem>
                ) : (
                  <React.Fragment>
                    <GridItem xs={12} sm={12} md={6}>
                      <p>{dropMessage}</p>
                      <input
                        accept="video/mp4"
                        className={`${classes.input} fileInput`}
                        id="videofile"
                        multiple
                        type="file"
                        onChange={this.handelFileUpload}
                        ref={this.fileInput}
                      />
                      <label
                        htmlFor="videofile"
                        className="d-block text-center"
                      >
                        Click icon to upload MP4 &nbsp; File should be less than
                        1 minute
                      </label>
                      <video
                        id="preview"
                        height="400"
                        width="500"
                        playsInline
                      />
                    </GridItem>
                    {recordedIsLoaded && (
                      <React.Fragment>
                        <GridItem xs={12} sm={12} md={6}>
                          <Button
                            disabled={disableUploadBtn}
                            color="primary"
                            onClick={() => this.uploadVideo()}
                          >
                            Upload Video
                          </Button>
                        </GridItem>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </GridContainer>
            </Popover>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(dashboardStyle)(UserCompletedRequestPage);
