import React from "react";
import { Redirect, Link } from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import configApi from "../services/config.json";
import Joi from "joi-browser";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Badge from "components/Badge/Badge.jsx";
import Danger from "components/Typography/Danger.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";
import logo from "assets/img/Fanbies_logo.png";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import image from "assets/img/sidebar-1.jpg";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
class UserProfile extends React.Component {
  anchorElBottom = null;
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      openBottom: false,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      secondaryTalent: "",
      extraTalent: "",
      primaryTalent: "",
      error: "",
      password: "",
      repassword: "",
      talentSelection: [],
      submitDisabled: false,
      isLoading: false,
      talentArry: {},
      useremail: "",
      claimItem: [],
      claimAccountAction: ["create", "completed"],
      claimAction: "",
      avaiableStatusArry: [
        "Unlisted",
        "UnAvailable",
        "Coming Soon",
        "Available"
      ],
      paymentType: "",
      paymentEmail: "",
      bankformerror: "",
      formMessage: "",
      charity: "",
      isLoadedBankForm: false,
      utype: 1,
      modal: false,
      passPopover: false,
      userDrop: false,
      modalMessage: "Are you sure you want to perform this action?",
      claimLoader: false,
      claimPopover: false,
      usertypePopover: false,
      referralPopover: false,
      dropLoader: false,
      bio: "",
      availableSlot: 0,
      requestRate: 0,
      callRate: 0,
      name: "",
      username: "",
      fbtag: "",
      twittertag: "",
      linkedintag: "",
      tiktag: "",
      igtag: "",
      dropMessage: "",
      recordedIsLoaded: false,
      disableUploadBtn: false,
      recordedStream: "",
      emailreferral: "",
      popoverMessage: "",
      featuredToggle: "0"
    };
    this.fileInput = React.createRef();
    this.uploadVideo = this.uploadVideo.bind(this);
    // this.fileInput = React.createRef();
  }

  handleDeleteModel(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleChangeEnabledToggle = event => {
    this.setState({ featuredToggle: event.target.value });
  };

  renderPrimaryTalent() {
    const { primaryTalent } = this.state;
    return primaryTalent ? <Badge color="warning">{primaryTalent}</Badge> : " ";
  }

  renderSecondaryTalent() {
    const { secondaryTalent } = this.state;
    return secondaryTalent ? (
      <Badge color="info">{secondaryTalent}</Badge>
    ) : (
      " "
    );
  }

  handelUserTypeUpdate = () => {
    let { usertype, uid } = this.state;
    if (usertype === 0) {
      usertype = 1;
    } else {
      usertype = 0;
    }
    axios
      .post(configApi.updateusertype, {
        usertype,
        uid
      })
      .then(res => {
        const uRes = res.data;
        if (uRes.success) window.location.reload();
      });
  };

  handleBankApiPopover(state) {
    this.setState({
      [state]: true,
      bankformerror: "",
      formMessage: ""
    });
    axios
      .post(configApi.getBankDetails, {
        uid: this.state.uid
      })
      .then(res => {
        const bankdetailsRes = res.data;
        this.setState({ isLoadedBankForm: true });
        if (!bankdetailsRes.success)
          return this.setState({ bankformerror: bankdetailsRes.message });
        if (bankdetailsRes.response <= 0) {
          this.setState({
            bankformerror: "We don't have your Bank Details on record."
          });
        } else {
          this.setState({
            paymentEmail: bankdetailsRes.response[0].email,
            paymentType: bankdetailsRes.response[0].payment_type
          });
        }
      })
      .catch(() => null);
  }

  handleCloseButton(state) {
    this.setState({
      [state]: false
    });
  }

  renderExtraTalent() {
    const { extraTalent } = this.state;
    return extraTalent ? <Badge color="success">{extraTalent}</Badge> : " ";
  }

  getTalentList = () => {
    return axios.post(configApi.getUserTalent);
  };

  getUser = () => {
    //usertype: this.props.location.data.utype
    return axios.post(configApi.admingetuserprofile, {
      username: this.props.match.params.username
    });
  };

  loadprofile() {
    axios
      .all([this.getUser(), this.getTalentList()])
      .then(res => {
        const userRes = res[0].data;
        const talentlistRes = res[1].data;
        if (!userRes.success) return this.setState({ error: userRes.message });

        this.setState({ talentArry: talentlistRes.response });
        this.setState({
          talentSelection: _.map(talentlistRes.response, "label")
        });
        if (userRes.response[0].primary_talent !== 0) {
          let filterPrimaryTalent = _.filter(talentlistRes.response, [
            "id",
            userRes.response[0].primary_talent
          ]);
          this.setState({ primaryTalent: filterPrimaryTalent[0].label });
        } else {
          this.setState({ primaryTalent: 0 });
        }

        if (userRes.response[0].secondary_talent !== 0) {
          let filterSecondaryTalent = _.filter(talentlistRes.response, [
            "id",
            userRes.response[0].secondary_talent
          ]);
          this.setState({ secondaryTalent: filterSecondaryTalent[0].label });
        } else {
          this.setState({ secondaryTalent: 0 });
        }

        if (userRes.response[0].extra_talent !== 0) {
          let filterExtraTalent = _.filter(talentlistRes.response, [
            "id",
            userRes.response[0].extra_talent
          ]);
          this.setState({ extraTalent: filterExtraTalent[0].label });
        } else {
          this.setState({ extraTalent: 0 });
        }
        this.setState({
          uid: userRes.response[0].id,
          name: userRes.response[0].name,
          username: userRes.response[0].username,
          profilepic: userRes.response[0].picture,
          usertype: userRes.response[0].usertype,
          useremail: userRes.response[0].email,
          bio: userRes.response[0].bio,
          requestRate: userRes.response[0].shoutrate,
          callRate: userRes.response[0].callrate,
          availableSlot: userRes.response[0].available_slot,
          fbtag:
            userRes.response[0].fb_id === null ? "" : userRes.response[0].fb_id,
          twittertag:
            userRes.response[0].twitter_id === null
              ? ""
              : userRes.response[0].twitter_id,
          linkedintag:
            userRes.response[0].linkedin_id === null
              ? ""
              : userRes.response[0].linkedin_id,
          tiktag:
            userRes.response[0].tik_id === null
              ? ""
              : userRes.response[0].tik_id,
          igtag:
            userRes.response[0].ig_id === null ? "" : userRes.response[0].ig_id,
          userstatus: userRes.response[0].active,
          charity: userRes.response[0].profession,
          featuredToggle: JSON.stringify(userRes.response[0].featured)
        });
      })
      .catch(() => null);
  }
  componentDidMount() {
    this.validatToken();
  }

  validatToken = () => {
    axios
      .post(configApi.validatetoken, {
        jwtoken: localStorage.getItem("token")
      })
      .then(res => {
        const tokenRes = res.data;
        if (!tokenRes.success) return <Redirect to="/login" />;

        this.setState({ utype: tokenRes.response.utype });
        if (this.state.utype < 2) return <Redirect to="/dashboard" />;
        this.loadprofile();
      })
      .catch(() => null);
  };

  deleteUser = () => {
    this.handleClose("modal");
    axios
      .post(configApi.deleteuser, {
        uid: this.state.uid,
        jwtoken: localStorage.getItem("token")
      })
      .then(res => {
        const tokenRes = res.data;
        if (!tokenRes.success) {
          this.setState({
            modalMessage: "User could not be Deleted at this moment. Try again?"
          });
          this.handleDeleteModel("modal");
        }
        this.props.history.replace("/normaluser");
      })
      .catch(() => null);
  };

  handelSelectedStatus = val => {
    let valueSet;
    if (val.target.textContent === "Unlisted") {
      valueSet = 0;
    } else if (val.target.textContent === "UnAvailable") {
      valueSet = 1;
    } else if (val.target.textContent === "Coming Soon") {
      valueSet = 2;
    } else {
      valueSet = 3;
    }

    this.setState({ userstatus: valueSet });
  };

  handelSelectedPrimaryTalent = val => {
    this.setState({ primaryTalent: val.target.textContent });
  };

  handelSelectedSecondaryTalent = val => {
    this.setState({ secondaryTalent: val.target.textContent });
  };

  handelSelectedExtraTalent = val => {
    this.setState({ extraTalent: val.target.textContent });
  };

  handelClaimAction = val => {
    this.setState({ claimAction: val.target.textContent });
  };

  handleChange = ({ currentTarget: input }) => {
    const formState = { ...this.state };
    formState[input.name] = input.value;
    this.setState(formState);
  };

  editProfile = () => {
    const {
      extraTalent,
      secondaryTalent,
      primaryTalent,
      talentArry,
      name,
      fbtag,
      twittertag,
      linkedintag,
      tiktag,
      igtag,
      useremail,
      bio,
      availableSlot,
      requestRate,
      userstatus,
      uid,
      charity,
      featuredToggle,
      callRate
    } = this.state;
    let indexOfPrimaryTalent = primaryTalent
      ? _.filter(talentArry, ["label", primaryTalent])[0].id
      : 0;
    let indexOfSecondaryTalent = secondaryTalent
      ? _.filter(talentArry, ["label", secondaryTalent])[0].id
      : 0;
    let indexOfExtraTalent = extraTalent
      ? _.filter(talentArry, ["label", extraTalent])[0].id
      : 0;
    axios
      .post(configApi.dashboardupdateuser, {
        fbtag,
        twittertag,
        linkedintag,
        tiktag,
        igtag,
        bio,
        name,
        useremail,
        primary_talent: indexOfPrimaryTalent,
        secondary_talent: indexOfSecondaryTalent,
        extra_talent: indexOfExtraTalent,
        availableSlot,
        requestRate,
        userstatus,
        uid,
        profession: charity,
        featured: featuredToggle,
        callrate: callRate
      })
      .then(res => {
        const updateRes = res.data;
        this.setState({ submitDisabled: false });
        if (!updateRes.success)
          return this.setState({ error: updateRes.message });
        // update state
        this.setState({
          uid: updateRes.response[0].id,
          name: updateRes.response[0].name,
          useremail: updateRes.response[0].email,
          bio: updateRes.response[0].bio,
          requestRate: updateRes.response[0].shoutrate,
          callRate: updateRes.response[0].callrate,
          availableSlot: updateRes.response[0].available_slot,
          fbtag: updateRes.response[0].fb_id,
          twittertag: updateRes.response[0].twitter_id,
          linkedintag: updateRes.response[0].linkedin_id,
          tiktag: updateRes.response[0].tik_id,
          igtag: updateRes.response[0].ig_id,
          userstatus: updateRes.response[0].active,
          primary_talent: updateRes.response[0].primary_talent,
          secondary_talent: updateRes.response[0].secondary_talent,
          extra_talent: updateRes.response[0].extra_talent,
          charity: updateRes.response[0].profession
        });
        window.location.reload();
      })
      .catch(() => {
        this.setState({ submitDisabled: false });
      });
  };

  validateForm = e => {
    e.preventDefault();
    const {
      availableSlot,
      useremail,
      name,
      requestRate,
      callRate
    } = this.state;

    const validatorObj = {
      availableSlot,
      useremail,
      name,
      requestRate,
      callRate
    };
    const result = Joi.validate(validatorObj, this.schema);
    this.setState({ error: "" });
    if (result.error !== null)
      return this.setState({ error: result.error.details[0].message });

    this.setState({ submitDisabled: true });
    this.editProfile();
  };

  confirmPaymentMade = () => {
    const { paymentEmail, paymentType, uid } = this.state;
    axios
      .post(configApi.adminconfirmpayout, {
        email: paymentEmail,
        paymentType,
        uid
      })
      .then(res => {
        const bankdetailsRes = res.data;
        this.setState({ submitDisabled: false });
        if (!bankdetailsRes.success)
          return this.setState({ formMessage: bankdetailsRes.message });
        this.setState({
          formMessage: bankdetailsRes.message
        });
      })
      .catch(() => {
        this.setState({ submitDisabled: false });
      });
  };

  handlePicUpdate = () => {
    //imageChangerForm
    this.setState({ isLoading: true });
    let data = new FormData();
    data.append("file", this.fileInput.current.files[0]);
    data.set("id", this.state.uid);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data,
      url: configApi.updatepic
    };
    axios(options)
      .then(res => {
        const uRes = res.data;
        this.setState({
          profilepic: uRes.response,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  // schema for form validation
  schema = {
    useremail: Joi.string()
      .required()
      .email({ minDomainAtoms: 2 })
      .label("Email value "),
    name: Joi.string()
      .min(3)
      .max(99)
      .required()
      .label("Name value "),
    availableSlot: Joi.number()
      .integer()
      .min(0)
      .max(99)
      .required()
      .label("Available Slot should be number "),
    requestRate: Joi.number()
      .integer()
      .min(0)
      .max(2999)
      .required()
      .label("Rate should be number greater than 1 "),
    callRate: Joi.number()
      .integer()
      .min(0)
      .max(199)
      .required()
      .label("Video call rate should be number greater than 1  ")
  };

  // Schema for bank detail form
  bankdetailformschema = {
    sortcode: Joi.number().label("Sort Code should be number "),
    accnumber: Joi.number().label("Account Number should be number ")
  };

  // Schema for referral detail form
  referralformschema = {
    Email: Joi.string()
      .required()
      .email({ minDomainAtoms: 2 })
      .label("Email value ")
  };

  PasswordSchema = {
    password: Joi.string()
      .required()
      .regex(/^[a-zA-Z0-9]{6,30}$/)
      .label("Password should be length 6 - 30 with multiple character. "),
    repassword: Joi.string()
      .valid(Joi.ref("password"))
      .label("Confirm Password is not equal to Password ")
  };

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  handelPasswordUpdate = e => {
    e.preventDefault();
    const validatorObj = {
      repassword: this.state.repassword,
      password: this.state.password
    };
    const result = Joi.validate(validatorObj, this.PasswordSchema);
    this.setState({ resetError: "" });
    if (result.error !== null)
      return this.setState({ resetError: result.error.details[0].message });

    this.setState({ submitDisabled: true });

    this.updatePassword();
  };

  updatePassword = () => {
    const { password, uid } = this.state;
    axios
      .post(configApi.updateuserpasswordbyid, {
        password,
        uid,
        jwtoken: localStorage.getItem("token")
      })
      .then(res => {
        const resetRes = res.data;
        this.setState({ submitDisabled: false });
        if (!resetRes.success)
          return this.setState({ resetError: resetRes.message });
        this.setState({
          resetError: resetRes.message
        });
      })
      .catch(() => {
        this.setState({ submitDisabled: false });
      });
  };

  handelClaimUpdate = () => {
    const { claimAction, uid, useremail, name } = this.state;
    if (claimAction) {
      axios
        .post(configApi.updateuseraccountclaim, {
          actiontype: claimAction,
          uid,
          useremail,
          username: name,
          jwtoken: localStorage.getItem("token")
        })
        .then(res => {
          const claimRes = res.data;
          this.setState({
            submitDisabled: false,
            claimMessage: claimRes.message
          });
        })
        .catch(() => {
          this.setState({ submitDisabled: false });
        });
    }
  };

  handelReferralUpdate = () => {
    this.setState({ popoverMessage: "" });
    const { emailreferral, uid } = this.state;
    const validatorObj = { Email: emailreferral };
    const result = Joi.validate(validatorObj, this.referralformschema);
    if (result.error !== null)
      return this.setState({ popoverMessage: result.error.details[0].message });

    axios
      .post(configApi.updatereferral, {
        email: emailreferral,
        uid
      })
      .then(res => {
        const referralRes = res.data;
        this.setState({ submitDisabled: false });
        if (!referralRes.success)
          return this.setState({ popoverMessage: referralRes.message });
        this.setState({
          popoverMessage: referralRes.message
        });
      })
      .catch(() => null);
  };

  deleteReferralDetails = () => {
    axios
      .post(configApi.deleteuserreferral, {
        uid: this.state.uid,
        jwtoken: localStorage.getItem("token")
      })
      .then(res => {
        const referralRes = res.data;
        this.setState({ submitDisabled: false });
        if (!referralRes.success)
          return this.setState({ popoverMessage: referralRes.message });
        this.setState({
          popoverMessage: referralRes.message
        });
      })
      .catch(() => null);
  };

  openReferral = () => {
    this.setState({ referralPopover: true });
    axios
      .post(configApi.getreferraldetails, {
        uid: this.state.uid
      })
      .then(res => {
        const emailRes = res.data;
        this.setState({ submitDisabled: false });
        if (!emailRes.success)
          return this.setState({ popoverMessage: emailRes.message });

        this.setState({ emailreferral: emailRes.response[0].email });
      })
      .catch(() => null);
  };

  openClaimAccount = () => {
    this.setState({ claimLoader: true, claimPopover: true });

    axios
      .post(configApi.getclaimstatus, {
        uid: this.state.uid
      })
      .then(res => {
        const statusRes = res.data;
        this.setState({ submitDisabled: false });
        if (!statusRes.success)
          return this.setState({ claimMessage: statusRes.message });
        this.setState({
          claimItem: [...statusRes.response],
          claimLoader: false
        });
      })
      .catch(() => {
        this.setState({ submitDisabled: false, claimLoader: false });
      });
  };

  uploadVideo = () => {
    let data = new FormData();
    const { uid, recordedStream, username } = this.state;
    data.append("file", recordedStream);
    data.set("video_owner_id", uid);
    data.set("ownername", username);

    let previewVideo = document.querySelector("video#preview");
    previewVideo.srcObject = null;
    previewVideo.src = null;
    //User should be able to submit one recording per session.
    this.setState({
      disableUploadBtn: true,
      dropLoader: true
    });
    setTimeout(() => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data,
        url: configApi.dropvideobyuserid
      };
      axios(options).then(res => {
        const resp = res.data;
        if (!resp.success)
          return this.setState({
            dropMessage: resp.message
          });

        this.setState({
          dropMessage: resp.message,
          dropLoader: false
        });
      });
    }, 1000);
  };

  handelFileUpload = () => {
    let _this = this;
    this.setState({ dropMessage: "" });
    let previewVideo = document.querySelector("video#preview");
    previewVideo.preload = "metadata";

    previewVideo.onloadedmetadata = () => {
      window.URL.revokeObjectURL(previewVideo.src);
      if (previewVideo.duration > 60) {
        previewVideo.srcObject = null;
        previewVideo.src = null;
        return _this.setState({
          dropMessage:
            "Invalid Video! Video duration should less than 60 second / 1 mintue",
          recordedIsLoaded: false
        });
      }
    };
    previewVideo.srcObject = null;
    previewVideo.src = null;
    previewVideo.src = window.URL.createObjectURL(
      this.fileInput.current.files[0]
    );
    this.setState({
      recordedStream: this.fileInput.current.files[0],
      recordedIsLoaded: true
    });
    previewVideo.controls = true;
    previewVideo.play();
  };

  handleUploadClose = state => {
    let previewVideo = document.querySelector("video#preview");
    if (previewVideo !== null) {
      previewVideo.srcObject = null;
      previewVideo.src = null;
    }
    this.setState({
      [state]: false,
      recordedIsLoaded: false
    });
  };

  render() {
    const { classes, ...rest } = this.props;
    const {
      primaryTalent,
      talentSelection,
      secondaryTalent,
      extraTalent,
      submitDisabled,
      error,
      name,
      username,
      profilepic,
      usertype,
      useremail,
      bio,
      requestRate,
      availableSlot,
      fbtag,
      twittertag,
      linkedintag,
      tiktag,
      igtag,
      isLoading,
      avaiableStatusArry,
      paymentType,
      paymentEmail,
      bankformerror,
      formMessage,
      isLoadedBankForm,
      utype,
      modalMessage,
      claimItem,
      charity,
      dropMessage,
      recordedIsLoaded,
      disableUploadBtn,
      emailreferral,
      popoverMessage,
      callRate
    } = this.state;

    let navlinks;
    if (utype < 2) {
      navlinks = _.filter(routes, ["type", "default"]);
    } else {
      navlinks = routes;
    }
    const userstatus = parseInt(this.state.userstatus, 10);
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={navlinks}
          logoText={""}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div className={classes.mainPanel}>
          <header className="customHeader">User Page</header>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardAvatar profile>
                  {isLoading ? (
                    <div className="text-center pos-rel">
                      <div className="overlay transparent">
                        <div className="lds-ripple pos-ab top-0">
                          <div />
                          <div />
                        </div>
                      </div>
                    </div>
                  ) : (
                    " "
                  )}
                  <input
                    accept="image/*"
                    className={`${classes.input} fileInput`}
                    id="profilepic"
                    multiple
                    type="file"
                    onChange={this.handlePicUpdate}
                    ref={this.fileInput}
                  />
                  <label className="clickable-icon" htmlFor="profilepic">
                    <img
                      src={profilepic}
                      alt={`${name} profile pic`}
                      className={`img-align-center profileImg`}
                    />
                  </label>
                </CardAvatar>
                <CardBody profile>
                  <h6 className={classes.cardCategory}>{name}</h6>
                  {usertype >= 1 ? (
                    <div>
                      <div className="m-v-md">
                        {this.renderPrimaryTalent()}
                        {this.renderSecondaryTalent()}
                        {this.renderExtraTalent()}
                      </div>
                      <p className={classes.description}>{bio}</p>
                      <Button
                        color="primary"
                        round
                        onClick={() => this.handleBankApiPopover("openBottom")}
                      >
                        Bank Details
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {utype >= 2 && usertype >= 1 ? (
                    <React.Fragment>
                      <Link
                        to={{
                          pathname: `/usercompleted/${username}`
                        }}
                      >
                        View User Completed Request
                      </Link>
                      <br />
                      <Link
                        to={{
                          pathname: `/userpendings/${username}`,
                          data: { celebEmail: useremail, celebName: name }
                        }}
                      >
                        View User Pending Request
                      </Link>
                      <br />
                      <Button
                        color="rose"
                        onClick={() => this.setState({ passPopover: true })}
                      >
                        Update User Password
                      </Button>
                      <Button
                        color="rose"
                        simple
                        onClick={() => this.setState({ userDrop: true })}
                      >
                        Upload User Drop Promo
                      </Button>
                      <Button
                        color="rose"
                        simple
                        onClick={() => this.openClaimAccount()}
                      >
                        Claim Account
                      </Button>
                      <Button
                        color="rose"
                        simple
                        onClick={() => this.openReferral()}
                      >
                        Referral Account
                      </Button>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <Button
                    color="info"
                    onClick={() => this.setState({ usertypePopover: true })}
                  >
                    Update user type
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={`${classes.cardTitleWhite} font-size-lg`}>
                    Edit Profile
                  </h4>
                </CardHeader>
                <CardBody>
                  <Danger>{error}</Danger>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Username"
                        id="username-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          type: "text",
                          value: username
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email"
                        id="email-address"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          name: "useremail",
                          onChange: this.handleChange,
                          value: useremail
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Name"
                        id="common-name"
                        className="font-bold"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "name",
                          onChange: this.handleChange,
                          value: name
                        }}
                      />
                    </GridItem>
                    {usertype >= 1 ? (
                      <React.Fragment>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Your Available Slot"
                            id="slots"
                            className="font-bold"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "number",
                              name: "availableSlot",
                              onChange: this.handleChange,
                              value: availableSlot
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Your Request Rate($)"
                            id="shoutrate-disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "number",
                              name: "requestRate",
                              onChange: this.handleChange,
                              value: requestRate
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Your Video Call Rate($)"
                            id="callrate-disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "number",
                              name: "callRate",
                              onChange: this.handleChange,
                              value: callRate
                            }}
                          />
                        </GridItem>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  {usertype >= 1 ? (
                    <React.Fragment>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                          <div className="d-inline-block m-v-md">
                            <CustomDropdown
                              buttonText="Status:"
                              buttonProps={{
                                color: "primary",
                                round: true
                              }}
                              dropdownList={avaiableStatusArry}
                              onClick={this.handelSelectedStatus}
                            />
                          </div>
                          <span className="m-h-md d-inline-block">
                            {userstatus === 0 ? "Unlisted" : ""}
                            {userstatus === 1 ? "Unavailable" : ""}
                            {userstatus === 2 ? "Coming Soon" : ""}
                            {userstatus === 3 ? "Available" : ""}
                          </span>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <p>Featured on Landing Page:</p>
                          <div className={`content-left`}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={this.state.featuredToggle === "0"}
                                  onChange={this.handleChangeEnabledToggle}
                                  value="0"
                                  name="radio button enabled"
                                  aria-label="No"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label
                              }}
                              label="No"
                            />
                          </div>
                          <div className={`content-left`}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={this.state.featuredToggle === "1"}
                                  onChange={this.handleChangeEnabledToggle}
                                  value="1"
                                  name="radio button enabled"
                                  aria-label="Yes"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label
                              }}
                              label="Yes"
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Facebook Tag"
                            id="fbTag"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              name: "fbtag",
                              onChange: this.handleChange,
                              value: fbtag
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Instagram Tag"
                            id="igtag"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              name: "igtag",
                              onChange: this.handleChange,
                              value: igtag
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Twitter Tag"
                            id="twitterTag"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              name: "twittertag",
                              onChange: this.handleChange,
                              value: twittertag
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Linkedin Tag"
                            id="linkedintag"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              name: "linkedintag",
                              onChange: this.handleChange,
                              value: linkedintag
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="TikTok Tag"
                            id="tiktag"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              name: "tiktag",
                              onChange: this.handleChange,
                              value: tiktag
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                          <CustomInput
                            labelText="Charity"
                            id="charityTag"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              name: "charity",
                              onChange: this.handleChange,
                              value: charity
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <InputLabel style={{ color: "#AAAAAA" }}>
                            About
                          </InputLabel>
                          <CustomInput
                            labelText="Biography"
                            id="about-me"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              multiline: true,
                              name: "bio",
                              onChange: this.handleChange,
                              value: bio,
                              rows: 5
                            }}
                          />
                          <div>
                            <p>Select Talent Badges</p>
                            <div className="d-inline-block">
                              <CustomDropdown
                                buttonText="Primary Talent"
                                buttonProps={{
                                  color: "warning",
                                  round: true
                                }}
                                dropdownList={talentSelection}
                                onClick={this.handelSelectedPrimaryTalent}
                              />
                            </div>
                            <span className="m-h-md d-inline-block">
                              {primaryTalent !== 0
                                ? primaryTalent
                                : "Please Select"}
                            </span>
                          </div>
                          <hr />
                          <div>
                            <div className="d-inline-block">
                              <CustomDropdown
                                buttonText="Secondary Talent"
                                buttonProps={{
                                  color: "info",
                                  round: true
                                }}
                                dropdownList={talentSelection}
                                onClick={this.handelSelectedSecondaryTalent}
                              />
                            </div>
                            <span className="m-h-md d-inline-block">
                              {secondaryTalent !== 0
                                ? secondaryTalent
                                : "Please Select"}
                            </span>
                          </div>
                          <hr />
                          <div>
                            <div className="d-inline-block">
                              <CustomDropdown
                                buttonText="Extra Talent"
                                buttonProps={{
                                  color: "success",
                                  round: true
                                }}
                                dropdownList={talentSelection}
                                onClick={this.handelSelectedExtraTalent}
                              />
                            </div>
                            <span className="m-h-md d-inline-block">
                              {extraTalent !== 0
                                ? extraTalent
                                : "Please Select"}
                            </span>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <Popover
                    classes={{
                      paper: classes.popover
                    }}
                    open={this.state.openBottom}
                    anchorEl={this.anchorElBottom}
                    anchorReference={"anchorEl"}
                    onClose={() => this.handleCloseButton("openBottom")}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center"
                    }}
                  >
                    <h3 className={`${classes.popoverHeader} font-bold p-a-md`}>
                      User Bank Information
                    </h3>
                    <p>{bankformerror}</p>
                    <p className="secondary-color">{formMessage}</p>
                    <div className={`${classes.popoverBody} p-a-md p-t-none`}>
                      {isLoadedBankForm === true ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Payment Type"
                              id="paymentType"
                              className="font-bold"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "text",
                                name: "paymenttype",
                                disabled: true,
                                value: `${paymentType}`
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Other (PayPal/PayStack/Cash App)"
                              id="paypalEmail"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "text",
                                name: "email",
                                disabled: true,
                                value: `${paymentEmail}`
                              }}
                            />
                          </GridItem>
                          <div className="pos-rel width-full ">
                            <Button
                              color="primary"
                              onClick={this.confirmPaymentMade}
                              disabled={submitDisabled}
                              className="floatRight"
                            >
                              Update Bank Detail
                            </Button>
                          </div>
                        </GridContainer>
                      ) : (
                        <div className="text-center pos-rel">
                          <div className="overlay transparent">
                            <div className="lds-ripple pos-ab top-0">
                              <div />
                              <div />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Popover>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal
                    }}
                    open={this.state.modal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("modal")}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <h4 className={`d-inline-block ${classes.modalTitle}`}>
                        Delete User
                      </h4>
                      <IconButton
                        className={`d-inline-block floatRight ${
                          classes.modalCloseButton
                        }`}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => this.handleClose("modal")}
                      >
                        <Close className={classes.modalClose} />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      id="modal-slide-description"
                      className={classes.modalBody}
                    >
                      <h5>{modalMessage}</h5>
                    </DialogContent>
                    <DialogActions
                      className={
                        classes.modalFooter + " " + classes.modalFooterCenter
                      }
                    >
                      <React.Fragment>
                        <Button
                          color="success"
                          simple
                          onClick={() => this.handleClose("modal")}
                        >
                          Never Mind
                        </Button>
                        <Button onClick={() => this.deleteUser()} color="rose">
                          Yes
                        </Button>
                      </React.Fragment>
                    </DialogActions>
                  </Dialog>
                </CardBody>
                <CardFooter>
                  {usertype >= 1 ? (
                    <Button
                      color="primary"
                      onClick={this.validateForm}
                      disabled={submitDisabled}
                    >
                      Update Profile
                    </Button>
                  ) : (
                    ""
                  )}
                  {utype >= 2 && usertype === 0 ? (
                    <Button
                      color="danger"
                      onClick={() => this.handleDeleteModel("modal")}
                    >
                      Delete User
                    </Button>
                  ) : (
                    ""
                  )}
                </CardFooter>
              </Card>
              <Popover
                classes={{
                  paper: classes.popover
                }}
                open={this.state.userDrop}
                anchorEl={this.anchorElBottom}
                anchorReference={"anchorEl"}
                onClose={() => this.handleUploadClose("userDrop")}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
              >
                <Button
                  onClick={() => this.handleUploadClose("userDrop")}
                  justIcon
                  round
                  className="floatRight"
                  color="warning"
                >
                  <Close style={{ color: "#FFFFFF" }} />
                </Button>
                <h3>Upload user drop from Admin dashboard</h3>
                <p>Select / Upload file:</p>
                <GridContainer>
                  {this.state.dropLoader === true ? (
                    <GridItem xs={12} sm={12} md={4}>
                      <div className="text-center pos-rel min-height-sm">
                        <div className="overlay transparent">
                          <div className="lds-ripple pos-ab top-0">
                            <div />
                            <div />
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  ) : (
                    <React.Fragment>
                      <GridItem xs={12} sm={12} md={6}>
                        <p>{dropMessage}</p>
                        <input
                          accept="video/mp4"
                          className={`${classes.input} fileInput`}
                          id="videofile"
                          multiple
                          type="file"
                          onChange={this.handelFileUpload}
                          ref={this.fileInput}
                        />
                        <label
                          htmlFor="videofile"
                          className="d-block text-center"
                        >
                          Click icon to upload MP4 &nbsp; File should be less
                          than 1 minute
                        </label>
                        <video
                          id="preview"
                          height="400"
                          width="500"
                          playsInline
                        />
                      </GridItem>
                      {recordedIsLoaded && (
                        <React.Fragment>
                          <GridItem xs={12} sm={12} md={6}>
                            <Button
                              disabled={disableUploadBtn}
                              color="primary"
                              onClick={() => this.uploadVideo()}
                            >
                              Upload Drop
                            </Button>
                          </GridItem>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </GridContainer>
              </Popover>
              <Popover
                classes={{
                  paper: classes.popover
                }}
                open={this.state.passPopover}
                anchorEl={this.anchorElBottom}
                anchorReference={"anchorEl"}
                onClose={() => this.handleCloseButton("passPopover")}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
              >
                <h3 className={`${classes.popoverHeader} font-bold p-a-md`}>
                  Update {name} password
                </h3>
                <p className="secondary-color">{this.state.resetError}</p>
                <div className={`${classes.popoverBody} p-a-md p-t-none`}>
                  <GridContainer>
                    {this.state.claimLoader === true ? (
                      <GridItem xs={12} sm={12} md={4}>
                        <div className="text-center pos-rel min-height-sm">
                          <div className="overlay transparent">
                            <div className="lds-ripple pos-ab top-0">
                              <div />
                              <div />
                            </div>
                          </div>
                        </div>
                      </GridItem>
                    ) : (
                      <React.Fragment>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Password"
                            id="password"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "password",
                              name: "password",
                              onChange: this.handleChange
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Confirm Password"
                            id="repassword"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "password",
                              name: "repassword",
                              onChange: this.handleChange
                            }}
                          />
                        </GridItem>
                        <div className="pos-rel width-full ">
                          <Button
                            color="primary"
                            onClick={this.handelPasswordUpdate}
                            disabled={submitDisabled}
                            className="floatRight"
                          >
                            Update Password
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                  </GridContainer>
                </div>
              </Popover>
              <Popover
                classes={{
                  paper: classes.popover
                }}
                open={this.state.claimPopover}
                anchorEl={this.anchorElBottom}
                anchorReference={"anchorEl"}
                onClose={() => this.handleCloseButton("claimPopover")}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
              >
                <h3 className={`${classes.popoverHeader} font-bold p-a-md`}>
                  Account Claim for {name}
                </h3>
                <p className="secondary-color">{this.state.claimMessage}</p>
                <div className={`${classes.popoverBody} p-a-md p-t-none`}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        Account Claim Status is currently :{" "}
                        {claimItem.length ? (
                          <b>{claimItem[0].status}</b>
                        ) : (
                          "Claim Account not recorded"
                        )}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomDropdown
                        buttonText="Status Type"
                        buttonProps={{
                          color: "info"
                        }}
                        dropdownList={this.state.claimAccountAction}
                        onClick={this.handelClaimAction}
                      />
                      <span className="m-h-md d-inline-block">
                        {this.state.claimAction}
                      </span>
                    </GridItem>
                    <div className="pos-rel width-full ">
                      <Button
                        color="primary"
                        onClick={this.handelClaimUpdate}
                        disabled={submitDisabled}
                        className="floatRight"
                      >
                        Update
                      </Button>
                    </div>
                  </GridContainer>
                </div>
              </Popover>
              <Popover
                classes={{
                  paper: classes.popover
                }}
                open={this.state.usertypePopover}
                anchorEl={this.anchorElBottom}
                anchorReference={"anchorEl"}
                onClose={() => this.handleCloseButton("usertypePopover")}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
              >
                <h3 className={`${classes.popoverHeader} font-bold p-a-md`}>
                  Update user type for {name}
                </h3>
                <div className={`${classes.popoverBody} p-a-md p-t-none`}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        Current type is:{" "}
                        {usertype === 0 ? "Normal" : "Influencer"}
                      </p>
                    </GridItem>
                    <div className="pos-rel width-full ">
                      <Button
                        color="primary"
                        onClick={this.handelUserTypeUpdate}
                        disabled={submitDisabled}
                        className="floatRight"
                      >
                        Update Usertype
                      </Button>
                    </div>
                  </GridContainer>
                </div>
              </Popover>
              <Popover
                classes={{
                  paper: classes.popover
                }}
                open={this.state.referralPopover}
                anchorEl={this.anchorElBottom}
                anchorReference={"anchorEl"}
                onClose={() => this.handleCloseButton("referralPopover")}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
              >
                <h3 className={`${classes.popoverHeader} font-bold p-a-md`}>
                  Referral Email Form
                </h3>
                <p>{popoverMessage}</p>
                <div className={`${classes.popoverBody} p-a-md p-t-none`}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email"
                        id="email-referral"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          name: "emailreferral",
                          onChange: this.handleChange,
                          value: `${emailreferral}`
                        }}
                      />
                    </GridItem>
                    <div className="pos-rel width-full ">
                      <Button
                        color="primary"
                        onClick={this.handelReferralUpdate}
                        disabled={submitDisabled}
                        className="floatRight"
                      >
                        Update Form
                      </Button>
                    </div>
                    <div className="pos-rel width-full ">
                      <Button
                        color="rose"
                        onClick={this.deleteReferralDetails}
                        disabled={submitDisabled}
                      >
                        Delete Record
                      </Button>
                    </div>
                  </GridContainer>
                </div>
              </Popover>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(dashboardStyle)(UserProfile);
